import { Mic, Pause, PlayArrow } from "@mui/icons-material";
import axios from "axios";
import { useEffect, useState } from "react";

const AudioPlayer = ({ audioUrl }) => {
    const [soundLevels, setSoundLevels] = useState([
        10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 
    ]);
    const [audio, setAudio] = useState(null);

    const play = () => {
        var offset = audio?.pausedAt;
    
        let sourceNode = audio?.context.createBufferSource();

        sourceNode.connect(audio?.context.destination);
        sourceNode.buffer = audio?.buffer;
        sourceNode.start(0, offset);

        sourceNode.onended = () => {
            setAudio({
                ...audio,
                pausedAt: 0,
                playing: false,
                sourceNode: null,
            })
        }

        setAudio({
            ...audio,
            startedAt: audio?.context.currentTime - offset,
            pausedAt: 0,
            playing: true,
            sourceNode: sourceNode,
        })
    }
    const pause = () => {
        var elapsed = audio?.context.currentTime - audio?.startedAt;
        audio?.sourceNode.disconnect();
        audio?.sourceNode.stop(0);
        setAudio({
            ...audio,
            pausedAt: elapsed,
        })
    }
    const stop = () => {
        if (audio?.sourceNode) {
            audio?.sourceNode.disconnect();
            audio?.sourceNode.stop(0);
        }
        setAudio({
            ...audio,
            pausedAt: 0,
            startedAt: 0,
            playing: false,
            sourceNode: null,
        })
    }
    const getCurrentTime = () => {
        if(audio?.pausedAt) {
            return audio?.pausedAt;
        }
        if(audio?.startedAt) {
            return audio?.context.currentTime - audio?.startedAt;
        }
        return 0;
    }
    const getDuration = () => {
        return audio?.buffer.duration;
    }

    const loadAudio = async () => {
        let audioCtx = new (window.AudioContext || window.webkitAudioContext)();

        
        let request = new XMLHttpRequest();
        request.open('GET', audioUrl, true /* async */ );
        request.responseType = 'arraybuffer';
        
        request.onload = function () {
            let audioData = request.response;
        
            audioCtx.decodeAudioData(audioData,
                function (buffer) {
                    console.log("Successfully decoded");
        
                    setAudio({
                        sourceNode: null,
                        startedAt: 0,
                        pausedAt: 0,
                        playing: false,
                        context: audioCtx,
                        buffer: buffer,
                    })
                },
                function (e) {
                    // on error callback
                    console.log("An error occurred");
                    console.log(e);
                });
        };
        
        request.setRequestHeader("Authorization", `Bearer ${localStorage.getItem('access_token')}`);
        request.setRequestHeader("ngrok-skip-browser-warning", "true")
        request.send();
        

    }
    useEffect(() => {
        loadAudio()

        // return () => {
        //     audio?.stop();
        // }
    }, []);

    useEffect(() => {
        console.log('audio', audio);
        const interval = setInterval(() => {
            if (!audio?.playing || audio?.pausedAt !== 0) {
                return
            } else {
                const newSoundLevels = soundLevels.map(soundLevel => {
                    const newSoundLevel = Math.floor(Math.random() * 100);
                    return newSoundLevel;
                })
                setSoundLevels(newSoundLevels);
            }
        }, 500);
        // audio?.playing ? audio?.start() : audio?.stop();
        return () => clearInterval(interval);
    }, [audio?.playing, audio?.pausedAt]);

    return (
        <div className="flex items-center justify-center bg-white rounded-lg p-2 min-h-[50px]">
            <Mic className="mr-2" fontSize="small" />
            <div className="grow self-stretch grid gap-1 items-center " style={{
                gridTemplateColumns: `repeat(${soundLevels.length}, 1fr)`,
            }}>
                {soundLevels.map((soundLevel, index) => (
                    <div 
                    key={index} 
                    className={'rounded-full'  + (audio?.playing && (index < (audio?.context?.currentTime - audio?.startedAt) / audio?.buffer.duration * soundLevels.length) ? ' bg-yellowCustom' : ' bg-gray-300')} 
                    style={{
                        height: `${soundLevel}%`,
                        transition: 'height 0.5s ease-in-out'
                    }}
                    // onClick={() => {
                    //     audio?.start(0,index / soundLevels.length * audio?.duration)
                    //     setIsPlaying(true);
                    // }}
                    />
                ))}
            </div>
            {
                (audio?.playing && audio?.pausedAt === 0) ? (
                    <Pause onClick={() => pause()} className="ml-2" fontSize="small" />
                ) : ( (audio) &&
                    <PlayArrow onClick={() => play()} className="ml-2" fontSize="small" />
                )
            }
        </div>
    )
}

export default AudioPlayer;