import Logo from "../../components/Logo";
import spiral from './../../assets/images/spiral.png';
import { ToggleButton, ToggleButtonGroup, Button, TextField, Autocomplete, ButtonGroup } from "@mui/material";
import { useState } from "react";
import PageIndicator from "../../components/PageIndicator";
import { useNavigate } from "react-router-dom";
import { createRequest } from "../../utils/crud";

const Onboarding = () => {
    const [page, setPage] = useState(0);
    const [basicNeeds, setBasicNeeds] = useState('');
    const [useCase, setUseCase] = useState('');
    const [userDetails, setUserDetails] = useState({});

    const navigate = useNavigate();

    const handleBasicNeeds = (event, newBasicNeeds) => {
        setBasicNeeds(newBasicNeeds);
    };

    const handleUseCase = (event, newUseCase) => {
        setUseCase(newUseCase);
    };

    const handleUseCaseSubmit = () => {
        useCase === 'school' ? setPage(2) : useCase === 'work' ? setPage(3) : setPage(3)
    }

    const submitData = async () => {
        const res = await createRequest('api/v1/onboarding/', {
            basicNeeds,
            useCase,
            ...userDetails
        }, localStorage.getItem('access_token'));
        if (res.status === 200 || res.status === 201) {
            navigate('/dashboard');
        }
    }

    const schoolQuestions = [
        { id: 'iAmA', label: 'I am a', options: ['High School Student', 'College Student', 'Graduate Student', 'Educator', 'Other'] },
        { id: 'areaOfStudy', label: 'My area of study is', options: ['Science', 'Math', 'Engineering', 'Arts', 'Humanities', 'Other'] },
        // { id: 'expectedGraduationYear', label: 'My expected graduation year is', options: ['2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035', '2036', '2037', '2038', '2039', '2040', '2041', '2042', '2043', '2044', '2045', '2046', '2047', '2048', '2049', '2050', '2051', '2052', '2053', '2054', '2055', '2056', '2057', '2058', '2059', '2060', '2061', '2062', '2063', '2064', '2065', '2066', '2067', '2068', '2069', '2070', '2071', '2072', '2073', '2074', '2075', '2076', '2077', '2078', '2079', '2080', '2081', '2082', '2083', '2084', '2085', '2086', '2087', '2088', '2089', '2090', '2091', '2092', '2093', '2094', '2095', '2096', '2097', '2098', '2099', '2100'] },
        // { id: 'studentAt', label: 'I am a student at', options: ['College 1', 'College 2', 'College 3', 'College 4', 'College 5', 'College 6', 'College 7', 'College 8', 'College 9', 'College 10'] },
    ]
    const workQuestions = [
        { id: 'iAmA', label: 'I am a', options: ['Student', 'Graduate', 'Professional', 'Other'] },
        { id: 'areaOfWork', label: 'My area of work is', options: ['UI/UX Design', 'Frontend Development', 'Backend Development', 'Fullstack Development', 'Other'] },
        { id: 'workTitle', label: 'My work title is', options: ['UI/UX Designer', 'Frontend Developer', 'Backend Developer', 'Fullstack Developer', 'Other'] },
        { id: 'industry', label: 'My industry is', options: ['Technology', 'Education', 'Healthcare', 'Finance', 'Other'] },
        { id: 'workFor', label: 'I work for' },
    ]

    return (
        <div className="h-screen w-screen flex justify-center items-center relative">
            <div className="absolute top-4 left-4 flex justify-center items-center">
                <Logo />
            </div>
            <div className="">
                <img src={spiral} alt="spiral" className="absolute top-0 right-0 h-2/5" />
            </div>
            { page === 0 &&             
                <div className="w-1/2 lg:w-1/3 h-full flex flex-col justify-center items-start gap-4">
                    <div className="text-3xl font-bold mt-4">
                        Welcome to Typeless
                    </div>
                    <div className="text-lg text-gray-500">
                        Tell us more about your needs
                    </div>
                    <PageIndicator currentPage={0} totalPages={3} />
                    <div className="text-xs text-gray-500 mt-4">
                        Make a selection below
                    </div>
                    <ToggleButtonGroup
                        value={basicNeeds}
                        aria-label="basicNeeds"
                        exclusive
                        className=""
                        size="small"
                        use="pill"
                        onChange={handleBasicNeeds}
                    >
                        <ToggleButton use='pill' value="research" aria-label="research">
                            Research
                        </ToggleButton>
                        <ToggleButton use='pill' value="consume" aria-label="consume">
                            Consume
                        </ToggleButton>
                        <ToggleButton use='pill' value="create" aria-label="create">
                            Create
                        </ToggleButton>
                        <ToggleButton use='pill' value="organize" aria-label="organize">
                            Organize
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <div className="mt-32 w-full">
                        <Button variant="contained" className="w-full" disabled={!basicNeeds} onClick={() => setPage(1)}>
                            Continue
                        </Button>
                    </div>
                </div>
            }
            { page === 1 &&
                <div className="w-1/2 lg:w-1/3 h-full flex flex-col justify-center items-start gap-4">
                    <div className="text-3xl font-bold text-start">
                        Tell us where you plan to use Typeless
                    </div>
                    <PageIndicator currentPage={1} totalPages={3} />
                    <div className="text-xs text-gray-500 mt-4">
                        Make a selection below
                    </div>
                    <ToggleButtonGroup
                        value={useCase}
                        aria-label="useCase"
                        exclusive
                        className=""
                        size="small"
                        use="pill"
                        onChange={handleUseCase}
                    >
                        <ToggleButton use='pill' value="school" aria-label="school" data-target={2}>
                            School
                        </ToggleButton>
                        <ToggleButton use='pill' value="work" aria-label="work" data-target={3}>
                            Work
                        </ToggleButton>
                        <ToggleButton use='pill' value="other" aria-label="other" data-target={4}>
                            Other Projects
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <div className="mt-32 w-full">
                        <Button variant="contained" className="w-full" disabled={!useCase} onClick={handleUseCaseSubmit}>
                            Continue
                        </Button>
                    </div>
                </div>        
            }
            {
                page === 2 &&
                <div className="w-1/2 lg:w-1/3 h-full flex flex-col justify-center items-start gap-4">
                    <div className="text-3xl font-bold text-start">
                        Tell us about your usage of TypeLess at School
                    </div>
                    <PageIndicator currentPage={2} totalPages={3} />
                    { schoolQuestions.map((question, index) => (
                        <Autocomplete
                            key={index}
                            id={question.id}
                            options={question.options}
                            size="small"
                            fullWidth
                            className="mt-4"
                            value={userDetails?.[question.id]}
                            onChange={(e, newValue) => setUserDetails({...userDetails, [question.id]: newValue})}
                            renderInput={(params) => <TextField {...params} label={question.label} variant="outlined" />}
                        />
                    ))}
                    <div className="mt-16 w-full">
                        <Button variant="contained" className="w-full" disabled={!userDetails?.iAmA || !userDetails?.areaOfStudy} onClick={submitData}>
                            Continue
                        </Button>
                    </div>
                </div>
            }
            {
                page === 3 &&
                <div className="w-1/2 lg:w-1/3 h-full flex flex-col justify-center items-start gap-4">
                    <div className="text-3xl font-bold text-start">
                        Tell us about your usage of TypeLess at Work
                    </div>
                    <PageIndicator currentPage={2} totalPages={3} />
                    { workQuestions.map((question, index) => (
                        question.options ? 
                            <Autocomplete
                                key={index}
                                id={question.id}
                                options={question.options}
                                size="small"
                                fullWidth
                                className="mt-4"
                                value={userDetails?.[question.id]}
                                onChange={(e, newValue) => setUserDetails({...userDetails, [question.id]: newValue})}
                                renderInput={(params) => <TextField {...params} label={question.label} variant="outlined" />}
                            />
                        :
                            <TextField
                                key={index}
                                id={question.id}
                                size="small"
                                fullWidth
                                className="mt-4"
                                value={userDetails?.[question.id]}
                                onChange={(e) => setUserDetails({...userDetails, [question.id]: e.target.value})}
                                label={question.label}
                                variant="outlined"
                            />
                    ))}
                    <div className="mt-16 w-full">
                        <Button variant="contained" className="w-full" disabled={!userDetails?.iAmA || !userDetails?.areaOfWork || !userDetails?.workTitle || !userDetails?.industry} onClick={submitData}>
                            Continue
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default Onboarding;