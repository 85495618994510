/** @format */

import Sidebar from "../../components/Sidebar";
import { useEffect, useState } from "react";
import { InputBase, Paper, Button, Tooltip, Dialog, ToggleButtonGroup, ToggleButton, AlertTitle, Alert, Snackbar } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TrackCard from "../../components/TrackCard";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation, useNavigate } from "react-router-dom";
import empty from "./../../assets/images/empty.png";
import { readRequest, createRequest } from "../../utils/crud";
import upload from "./../../assets/images/upload.svg";
import search_globe from "./../../assets/images/captive_portal.svg";
import add_note from "./../../assets/images/add_notes.svg";
import article from "./../../assets/images/article.svg";
import { FileUploader } from "react-drag-drop-files";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import vectorizingGif from './../../assets/lotties/Vectorizing doc.json'
import Lottie from 'react-lottie';
import Loader from "../../components/Loader";

const Dashboard = () => {
     const navigate = useNavigate();
     const location = useLocation();

     const [tracks, setTracks] = useState([]);
     const [remainingTracks, setRemainingTracks] = useState(0);
     const [searchString, setSearchString] = useState("");
     const [noCreditsModal, setNoCreditsModal] = useState(false);
     const [selectSourceModal, setSelectSourceModal] = useState(false);
     const [fileUploadModal, setFileUploadModal] = useState(false);
     const [fileUpload, setFileUpload] = useState({});
     const [source, setSource] = useState(null); 
     const [loading, setLoading] = useState(true);
     const [snackbarDetails, setSnackbarDetails] = useState({ message: "", severity: "success" });
     const [snackbar, setSnackbar] = useState(false);
  
     const access = localStorage.getItem("access_token");
     const fetchTracks = async (access_token = access) => {
          setLoading(true);
          const res = await readRequest("api/v1/tracks/", access_token);
          setLoading(false);
          console.log(res?.data, "res.data");
          if (res?.status === 200 || res?.status === 201) {
               setTracks(res?.data);
          } else {
               if (typeof res?.data?.detail === 'string'){
                    navigate("/", { state: { error: res?.data?.detail } });     
               } else {
                    navigate("/", { state: { error: 'Something went wrong!' } });
               }
               localStorage.removeItem("access_token");
          }
     }; 

     const getRemainingTracks = async (access_token = access) => {
          const res = await readRequest("api/v1/tracks/remaining_tracks/fetch", access_token);
          if (res?.status === 200 || res?.status === 201) {
               setRemainingTracks(res?.data === null ? 0 : res?.data);
          }
     };

     const handleNewTrack = () => {
          if (remainingTracks === 0) {
               setNoCreditsModal(true);
               return;
          }
          setSelectSourceModal(true);
     };

     useEffect(() => {
          const tmpFunc = async () => {
               await fetchTracks();
               await getRemainingTracks();
               if (location.state?.error) {
                    setSnackbarDetails({ message: location?.state?.error, severity: "error" });
                    setSnackbar(true);
               }
          };
          tmpFunc();
     }, []);

     return (
          <div className="flex relative">
               <Snackbar
                    open={snackbar}
                    autoHideDuration={6000}
                    onClose={() => {
                         setSnackbar(false);
                         setSnackbarDetails({});
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                    <Alert
                         onClose={() => {
                              setSnackbar(false);
                              setSnackbarDetails({});
                         }}
                         severity={snackbarDetails.severity}
                         sx={{ width: "100%" }}>
                         {snackbarDetails.message}
                    </Alert>
               </Snackbar>

               <Loader loading={loading} />
               {!getCookieConsentValue() && (
                    <CookieConsent
                         location="bottom"
                         buttonText="Accept"
                         cookieName="myAwesomeCookieName2"
                         style={{ background: "#2B373B" }}
                         buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                         expires={150}>
                         This website uses cookies to enhance the user experience. <span className="underline cursor-pointer text-xs"><a href='https://portal.termshub.io/typeless.io/cookie_policy/'>learn more</a></span>
                    </CookieConsent>
               )}
               <Dialog
                    open={noCreditsModal}
                    onClose={() => setNoCreditsModal(false)}
                    sx={{ borderRadius: "1rem" }}
               >
                    <div className="flex items-center justify-between p-4 h-48 flex-col text-center rounded-2xl min-w-[360px]">
                         <div></div>
                         No credits available. <br />
                         Please buy credits to create new journeys.
                         <Button
                              variant="contained"
                              className="ml-4"
                              onClick={() => navigate("/credits")}>
                              Go to store
                         </Button>
                    </div>
               </Dialog>
               <Dialog
                    open={selectSourceModal}
                    onClose={() => setSelectSourceModal(false)}
                    sx={{ borderRadius: "1rem" }}
               >
                    <div className="py-6 px-10 min-h-48 flex-col text-center rounded-2xl">
                         <div>Begin your journey</div>
                         <ToggleButtonGroup
                              value={source}
                              exclusive
                              onChange={(e, value) => setSource(value)}
                              aria-label="text alignment"
                              use="source"
                              className="items-stretch justify-center my-4">
                              <ToggleButton
                                   use="source"
                                   value="internet"
                                   aria-label="left aligned"
                                   className="flex items-center justify-center flex-col gap-4 w-max">
                                   <div>
                                        <img
                                             src={search_globe}
                                             alt="upload"
                                             className="scale-75"
                                        />
                                   </div>
                                   <div>
                                        Converse
                                        <div className="text-xs">(Query the internet)</div>
                                   </div>
                              </ToggleButton>
                              <ToggleButton
                                   use="source"
                                   value="file"
                                   aria-label="centered"
                                   className="flex items-center justify-center flex-col gap-4">
                                   <div>
                                        <img
                                             src={upload}
                                             alt="upload"
                                             className="scale-75"
                                        />
                                   </div>
                                   <div>
                                        Analyse
                                        <div className="text-xs">(Ask question from a file)</div>
                                   </div>
                              </ToggleButton>
                         </ToggleButtonGroup>
                         <div className="flex justify-center gap-4">
                              <div className="w-1/2 text-end">
                                   <Button
                                        variant="contained"
                                        className="ml-4"
                                        onClick={() => setSelectSourceModal(false)}
                                        sx={{ backgroundColor: "#D6D9DE", color: "#110A1C", "&:hover": { backgroundColor: "#D6D9DE" },  }}
                                        size="large"
                                   >
                                        Cancel
                                   </Button>
                              </div>
                              <div className="w-1/2 text-start">
                                   <Button
                                        variant="contained"
                                        className="ml-4"
                                        color="secondary"
                                        disabled={!source}
                                        onClick={() => {
                                             setSelectSourceModal(false);
                                             if (source === "internet") {
                                                  navigate("/create-track", { state: { source } });
                                             } else {
                                                  setFileUploadModal(true);
                                             }
                                        }}
                                        size="large"
                                   >
                                        Continue
                                   </Button>
                              </div>
                         </div>
                    </div>
               </Dialog>
               <Dialog
                    open={fileUploadModal}
                    onClose={() => setFileUploadModal(false)}
                    sx={{ borderRadius: "1rem" }}
               >
                    <div className="py-6 px-10 min-h-48 flex-col text-center min-w-[360px] rounded-2xl" onLoad={() => document.querySelector('.drop-area')?.click()}>
                         <div>Begin your journey</div>
                         <FileUploader
                              classes={'drop-area'}
                              required
                              maxSize={2}
                              types={["PDF"]}
                              handleChange={(file) => {
                                   setFileUpload({
                                        file: file,
                                        filename: file?.name,
                                   });
                              }}
                              onTypeError={(file) => {
                                   setFileUpload({
                                        error: "File type not supported",
                                   });
                              }}
                              onSizeError={(file) => {
                                   setFileUpload({
                                        error: 'File size should be less than 2 MB'
                                   })
                              }}
                              children={
                                   fileUpload?.error ? (
                                        <div className="flex items-center justify-center flex-col gap-4 border border-dashed border-error rounded-lg p-4 my-4 cursor-pointer text-error">
                                             {fileUpload?.error}
                                             <Button
                                                  variant="outlined"
                                                  color="error"
                                                  onClick={() => setFileUpload({})}>
                                                  Remove
                                             </Button>
                                        </div>
                                   ) : fileUpload?.file ? (
                                        <div className="flex items-center justify-center flex-col gap-4 border border-dashed border-[#1877F2] rounded-lg p-4 my-4 cursor-pointer">
                                             <img
                                                  src={article}
                                                  alt="uploaded"
                                                  className="scale-75"
                                             />
                                             <div className="text-[#1877F2] -mt-4">{fileUpload?.filename}</div>
                                             <Button
                                                  variant="outlined"
                                                  color="error"
                                                  onClick={() => setFileUpload({})}>
                                                  Remove
                                             </Button>
                                        </div>
                                   ) : (
                                        <div className="flex items-center justify-center flex-col gap-4 border border-dashed border-[#1877F2] rounded-lg p-4 my-4 cursor-pointer">
                                             <img
                                                  src={add_note}
                                                  alt="upload"
                                                  className="scale-75"
                                             />
                                             <div className="text-[#1877F2]">Select a PDF file to upload</div>
                                        </div>
                                   )
                              }
                         />
                         <div className="flex justify-center gap-4">
                              <Button
                                   variant="contained"
                                   className="ml-4"
                                   onClick={() => {
                                        setFileUploadModal(false);
                                        setSelectSourceModal(true);
                                   }}
                                   sx={{ backgroundColor: "#D6D9DE", color: "#110A1C", "&:hover": { backgroundColor: "#D6D9DE" } }}
                                   size="large"
                              >
                                   Go back
                              </Button>
                              {fileUpload?.file?.name && (
                                   <Button
                                        variant="contained"
                                        className="ml-4"
                                        color="secondary"
                                        onClick={() => {
                                             setSelectSourceModal(false);
                                             navigate("/create-track", { state: { source, fileUpload } });
                                        }}
                                        size="large"
                                   >
                                        Continue
                                   </Button>
                              )}
                         </div>
                    </div>
               </Dialog>

               <Sidebar />
               <div className="bg-[#F7F9FB] grow p-4 h-screen overflow-y-scroll">
                    <Alert severity="warning" icon={false} className="mb-2">
                         Closed Group Beta
                    </Alert>

                    <div className="flex justify-between items-center gap-4">
                         <Paper
                              className="grow px-2 py-0 flex items-center"
                              sx={{ boxShadow: "none" }}>
                              <SearchIcon />
                              <InputBase
                                   id="outlined-search"
                                   placeholder="Search for journey..."
                                   type="search"
                                   size="small"
                                   value={searchString}
                                   onChange={(e) => setSearchString(e.target.value)}
                                   className="grow"
                                   inputProps={{
                                        sx: {
                                             padding: ".5rem ",
                                        },
                                   }}
                              />
                         </Paper>
                         <div className="bg-white rounded-lg p-2">
                              <span className="text-[#1877f2] font-bold">{remainingTracks}</span> journeys remaining
                         </div>
                    </div>
                    <div className="font-bold text-2xl text-start mt-4">Your journeys</div>
                    <div className=" text-lg text-start mt-4">Current journeys</div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                         <Button
                              variant="outlined"
                              color="secondary"
                              className="flex flex-col items-center justify-center"
                              sx={{
                                   borderRadius: "1rem",
                                   backgroundColor: "#E8F1FE",
                                   minHeight: "8rem",
                              }}
                              onClick={handleNewTrack}>
                              <AddIcon fontSize="large" />
                              <div className="text-[#110a1c]">Create journey</div>
                         </Button>
                         {tracks
                              ?.filter((track) => new Date(track?.created_at) > Date.now() - 24 * 60 * 60 * 1000 && track?.title.toLowerCase().includes(searchString.toLowerCase()))
                              .map((track) => (
                                   <TrackCard
                                        track={track}
                                        key={track?.track_id}
                                        tracks={tracks}
                                        setTracks={setTracks}
                                        getRemainingTracks={getRemainingTracks}
                                        setLoading={setLoading}
                                        searchString={searchString}
                                   />
                              ))}
                    </div>
                    <div className=" text-lg text-start mt-4">
                         Past journeys
                         <span className="ml-1 text-sm text-[#88848D]">({tracks?.filter((track) => !(new Date(track?.created_at) > Date.now() - 24 * 60 * 60 * 1000) && track?.title.toLowerCase().includes(searchString.toLowerCase())).length})</span>
                         <span className="ml-1">
                              <Tooltip
                                   title="Any journey that wasn't opened it in the last day"
                                   placement="top">
                                   <InfoIcon
                                        fontSize="small"
                                        className="cursor-pointer"
                                   />
                              </Tooltip>
                         </span>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
                         {tracks
                              ?.filter((track) => !(new Date(track?.created_at) > Date.now() - 24 * 60 * 60 * 1000) && track?.title.toLowerCase().includes(searchString.toLowerCase()))
                              .map((track, index) => (
                                   <TrackCard
                                        track={track}
                                        key={index}
                                        tracks={tracks}
                                        setTracks={setTracks}
                                        getRemainingTracks={getRemainingTracks}
                                        setLoading={setLoading}
                                        searchString={searchString}
                                   />
                              ))}
                    </div>
                    {tracks?.filter((track) => !(new Date(track?.created_at) > Date.now() - 24 * 60 * 60 * 1000) && track?.title.toLowerCase().includes(searchString.toLowerCase())).length === 0 && (
                         <div className="text-[#88848D] text-center w-full flex flex-col items-center justify-center">
                              <img
                                   src={empty}
                                   alt="empty"
                                   className=""
                              />
                              You do not have any past journeys!
                         </div>
                    )}
               </div>
          </div>
     );
};

export default Dashboard;
