/** @format */

import Logo from "./Logo";
import FooterLogo from "../../assets/Landingpage/FooterLogo";
import Linkedin from "../../assets/Landingpage/LinkedIcon";

const Footer = () => {
   const innerWidth = window.innerWidth;
   return (
      <div className="w-full bg-black overflow-hidden flex flex-col items-center justify-center py-16 px-10 box-border text-left text-5xl text-new-version-white-100 font-inter-28px-bold">
         <div className="self-stretch flex lg:flex-row flex-col items-start justify-between">
            <div className="flex flex-col items-start justify-start gap-[40px] max-lg:w-[100%] max-lg:items-center ">
               {innerWidth > 1020 ? (
                  <Logo
                     typelessLogo="/typeless-logo@2x.png"
                     logoPosition="relative"
                     typeColor="#fff"
                  />
               ) : (
                  <FooterLogo />
               )}
               <div className="flex flex-row items-start justify-start gap-[20px]">
                  <img
                     className="w-12 relative cursor-pointer rounded-80xl h-12 overflow-hidden shrink-0"
                     alt=""
                     src="/frame-427318975.svg"
                  />
                  <img
                     className="w-12 relative cursor-pointer rounded-80xl h-12 overflow-hidden shrink-0"
                     alt=""
                     src="/frame-427318976.svg"
                  />
                  <span className=" cursor-pointer">
                     <Linkedin />
                  </span>
               </div>
            </div>
            <div className="flex lg:flex-row flex-col max-lg:mt-[3rem] max-lg:w-[100%] max-lg:items-center items-start justify-start xl:gap-[140px] gap-[2rem]">
               <div className="flex flex-col lg:items-start items-center justify-start lg:gap-[40px] gap-[1.5rem]">
                  <div className="relative tracking-[0.02em] font-semibold">Company</div>
                  <div className="flex flex-col lg:items-start items-center justify-start gap-[24px] text-lg">
                     <a
                        href="#home"
                        className="relative font-medium no-underline text-[#fff]">
                        Home
                     </a>
                     <a
                        href="#features"
                        className="relative font-medium no-underline text-[#fff]">
                        Features
                     </a>
                     <a
                        href="#pricing"
                        className="relative font-medium no-underline text-[#fff]">
                        Pricing
                     </a>
                  </div>
               </div>
               <div className="flex flex-col md:items-start items-center justify-start lg:gap-[40px] gap-[1.5rem]">
                  <div className="relative tracking-[0.02em] font-semibold">Legal Agreements</div>
                  <div className="flex flex-col lg:items-start items-center justify-start gap-[24px] text-lg">
                     <a
                        href="#"
                        className="relative font-medium no-underline text-[#fff]">
                        Privacy Policy
                     </a>
                     <a
                        href="#"
                        className="relative font-medium no-underline text-[#fff]">{`Terms & Conditions`}</a>
                     <a
                        href="#"
                        className="relative font-medium no-underline text-[#fff]">
                        Refund Policy
                     </a>
                  </div>
               </div>
               <div className="flex flex-col md:items-start items-center justify-start lg:gap-[40px] gap-[1.5rem]">
                  <div className="relative tracking-[0.02em] font-semibold">Contact</div>
                  <div className="flex flex-col lg:items-start items-center gap-[24px] text-lg">
                     <div className="w-[242px] relative h-[22px]">
                        <a
                           href="mailto:support@greyfeathers.in"
                           className="absolute top-[0px]  no-underline text-[#fff] left-[26px] font-medium">
                           support@greyfeathers.in
                        </a>
                        <img
                           className="absolute top-[5px] left-[0px] w-4 h-4 overflow-hidden"
                           alt=""
                           src="/mail.svg"
                        />
                     </div>
                     <div className="flex flex-row items-center justify-start gap-[10px] cursor-pointer">
                        <img
                           className="w-4 relative h-4 overflow-hidden shrink-0"
                           alt=""
                           src="/phone.svg"
                        />
                        <a
                           href="tel:+917418590412"
                           className="relative font-medium  no-underline text-[#fff] cu">
                           +91 74185 90412
                        </a>
                     </div>
                     <div className="w-[300px] relative h-[66px]">
                        <img
                           className="absolute top-[5px] left-[0px] w-4 h-4 overflow-hidden"
                           alt=""
                           src="/mappin.svg"
                        />
                        <div className="absolute top-[0px] left-[26px] max-lg:text-center font-medium inline-block w-[274px]">3/236-A, Nilla street, Krishna Nagar, Thirupillai, Madurai - 625014</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Footer;
