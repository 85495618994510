/** @format */

import React from "react";

const GoogleIcon = () => {
     return (
          <svg
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg">
               <g clip-path="url(#clip0_130_2860)">
                    <path
                         d="M22.0594 12.2222C22.0594 11.3999 21.9911 10.7999 21.8433 10.1777H12.2344V13.8888H17.8746C17.7609 14.8111 17.1469 16.1999 15.7823 17.1332L15.7631 17.2575L18.8013 19.5572L19.0118 19.5777C20.9449 17.8333 22.0594 15.2666 22.0594 12.2222Z"
                         fill="#4285F4"
                    />
                    <path
                         d="M12.2345 22C14.9978 22 17.3175 21.1111 19.0119 19.5778L15.7824 17.1333C14.9182 17.7222 13.7583 18.1333 12.2345 18.1333C9.52812 18.1333 7.23109 16.3889 6.41226 13.9778L6.29224 13.9877L3.13311 16.3766L3.0918 16.4889C4.77476 19.7555 8.23172 22 12.2345 22Z"
                         fill="#34A853"
                    />
                    <path
                         d="M6.41211 13.978C6.19605 13.3558 6.07101 12.6891 6.07101 12.0002C6.07101 11.3113 6.19605 10.6446 6.40074 10.0224L6.39502 9.88991L3.1963 7.46265L3.09164 7.51129C2.39801 8.86686 2 10.3891 2 12.0002C2 13.6113 2.39801 15.1335 3.09164 16.4891L6.41211 13.978Z"
                         fill="#FBAE05"
                    />
                    <path
                         d="M12.2345 5.86664C14.1563 5.86664 15.4526 6.67775 16.1918 7.35557L19.0801 4.6C17.3062 2.98889 14.9978 2 12.2345 2C8.23172 2 4.77477 4.24443 3.0918 7.51107L6.4009 10.0222C7.23109 7.61111 9.52812 5.86664 12.2345 5.86664Z"
                         fill="#EB4335"
                    />
               </g>
               <defs>
                    <clipPath id="clip0_130_2860">
                         <rect
                              width="24"
                              height="24"
                              fill="white"
                         />
                    </clipPath>
               </defs>
          </svg>
     );
};

export default GoogleIcon;
