/** @format */

import React from "react";
import PricingCard from "./PricingCard";

const Pricing = () => {
   const pricingCardData = [
      { type: "Free", price: "0", featuresOne: "2 journeys per month", featuresTwo: "20 questions per journey free", featuresThree: "PDF upload limit to 2.5 MB" },
      { type: "Basic", price: "5", featuresOne: "15 journeys", featuresTwo: "30 questions in each journey", featuresThree: "PDF upload limit to 5 MB" },
      {
         type: "Pro",
         price: "20",
         featuresOne: "100 journeys",
         featuresTwo: "50 questions in each journey",
         featuresThree: "PDF upload limit to 10 MB",
         featuresfour: "Audio transcription for notes (coming soon)",
      },
      {
         type: "Enterprise",
         price: "100",
         featuresOne: "100 journeys",
         featuresTwo: "50 questions in each journey",
         featuresThree: "PDF upload limit to 10 MB",
         featuresfour: "Audio transcription for notes (coming soon)",
      },
   ];
   return (
      <div
         id="pricing"
         className="max-sm:mt-[3rem]">
         <div>
            <img
               className="w-[100px] relative h-[101.5px]"
               alt=""
               src="/frame-427318990.svg"
            />
         </div>
         <h2 className="lg:text-[54px] text-[40px]">Pricing</h2>
         <p className="lg:text-[1.5rem] text-[1.1rem]">Find Your Perfect Fit: Choose from Our Range of Tailored Plans</p>
         <div className="grid lg:grid-cols-4 grid-cols-2 mb-[6rem] justify-between 2xl:gap-[3rem] xl:gap-[2rem] md:mt-[4rem] mt-[2rem] gap-[0.5rem]">
            {pricingCardData.map((item, index) => (
               <PricingCard
                  key={index}
                  type={item.type}
                  price={item.price}
                  pointOne={item.featuresOne}
                  pointTwo={item.featuresTwo}
                  pointThree={item.featuresThree}
                  pointFour={item.featuresfour}
               />
            ))}
         </div>
      </div>
   );
};

export default Pricing;
