/** @format */

import React from "react";
import load from "../../assets/lotties/loaderAnimation.json";
import Lottie from "react-lottie";

const Loader = ({ loading }) => {
     return (
          <>
               {loading && (
                    <div className="w-screen h-screen bg-[rgba(0,0,0,0.5)] flex items-center justify-center  absolute top-0 right-0 z-50">
                         <div className="w-[20vw] rounded-2xl h-[30vh] items-center flex justify-center">
                              <Lottie

                                   speed={1}
                                   options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: load,
                                        rendererSettings: {
                                             preserveAspectRatio: "xMidYMid slice",
                                        },
                                   }}
                                   height={200}
                                   width={200}
                              />
                              {/* <img src={load} alt ='loader' /> */}
                         </div>
                    </div>
               )}
          </>
     );
};

export default Loader;
