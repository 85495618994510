/** @format */

import { OutlinedInput, InputAdornment } from "@mui/material";
import { KeyboardVoiceOutlined, Link, DeleteOutlined, Shortcut, SettingsVoiceOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import audio from './../../assets/audio/subtle_text.mp3'
import { useRef } from "react";
import micAnimated from './../../assets/images/28459-voice-command (2).gif'

const InputQuestion = ({ returnQuestion, track = null, setTrack = null, ref, isEnter = false, setEnter=() => {} }) => {
   // const [hoveringPlus, setHoveringPlus] = useState(false);
   const [tmpQuestion, setTmpQuestion] = useState({});
   const [inputLink, setInputLink] = useState(false);

   const [listening, setListening] = useState(false)

   const input = useRef(null)
   let recognisation = new window.webkitSpeechRecognition()
   recognisation.continuous = true
   recognisation.onstart = () => {
      setListening(true)
   }
   // recognisation.onsoundend = () => {
   //    console.log(2);
   //    setListening(false)
   // }
   recognisation.onresult = (e) => {
      console.log(e.results)
      let transcript = ''
      for(const res of e.results){
         transcript += res[0].transcript
      }
      setTmpQuestion({ ...tmpQuestion, question: tmpQuestion?.question ? tmpQuestion?.question + " " + transcript : transcript })
   }

   useEffect(() => {
      if (setTrack) {
         setTrack({ ...track, questions: [tmpQuestion] });
      }
   }, [tmpQuestion]);

   useEffect(() => {
   }, [])

   return (
      <div className="overflow-hidden w-full">
         <OutlinedInput
            inputRef={(ref) => {
               isEnter && ref?.focus()
               setEnter(false)
            }}
            placeholder="Ask your question"
            fullWidth
            size="small"
            // startAdornment={
            //    <InputAdornment
            //       position="start"
            //       className="cursor-pointer">
            //       {tmpQuestion?.link === undefined && (
            //          <span
            //             className={"bg-[#1877f2] text-white rounded p-0.5 flex items-center gap-1 transition-all" + (hoveringPlus ? " w-24" : " w-7")}
            //             onMouseEnter={() => setHoveringPlus(true)}
            //             onMouseLeave={() => setHoveringPlus(false)}
            //             onClick={() => {
            //                setInputLink(true);
            //                setTmpQuestion({ ...tmpQuestion, link: "" });
            //             }}>
            //             <AddCircle />
            //             {hoveringPlus && <span className="text-sm">Add link</span>}
            //          </span>
            //       )}
            //    </InputAdornment>
            // }
            inputProps={{
               ref: input
            }}
            endAdornment={
               <InputAdornment
                  position="end"
                  className="cursor-pointer overflow-hidden w-8"
                  sx={{
                     height: '100%',
                  }}
               >
                  {listening ? (
                     <img src={micAnimated} alt="mic"style={{
                        transform: 'scale(5)',
                        zIndex: 1,
                     }}  onClick={() => {
                        setListening(false)
                        recognisation.stop()
                        input.current.focus()
                     }} />
                  ) : (
                     <KeyboardVoiceOutlined fontSize="small" color="primary" onClick={() =>{
                        new Audio(audio).play()
                        recognisation.start()
                     }} />
                  )}
               </InputAdornment>
            }
            // className="mt-10"
            value={tmpQuestion?.question}
            onChange={(e) => setTmpQuestion({ ...tmpQuestion, question: e.target.value })}
            onKeyDown={async (e) => {
               if (e.key === "Enter") {
                  await returnQuestion(tmpQuestion);
                  setTmpQuestion({});
                  setInputLink(false);
               }
            }}
         />
         <div className="w-11/12 self-end">
            {inputLink && (
               <OutlinedInput
                  name={`link`}
                  placeholder="Paste your link here"
                  fullWidth
                  size="small"
                  value={tmpQuestion?.link}
                  onChange={(e) => {
                     setTmpQuestion({ ...tmpQuestion, link: e.target.value });
                  }}
                  className="mt-2"
                  startAdornment={
                     <InputAdornment
                        position="start"
                        className="cursor-pointer rotate-[135deg] text-[#1877f2]">
                        <Link
                           fontSize="small"
                           color="secondary"
                        />
                     </InputAdornment>
                  }
                  endAdornment={
                     <InputAdornment
                        position="end"
                        className="cursor-pointer">
                        <DeleteOutlined
                           fontSize="small"
                           color="error"
                           onClick={() => {
                              setInputLink(false);
                              setTmpQuestion({ ...tmpQuestion, link: undefined });
                           }}
                        />
                     </InputAdornment>
                  }
                  onKeyDown={async (e) => {
                     if (e.key === "Enter") {
                        await returnQuestion(tmpQuestion);
                        setTmpQuestion({});
                        setInputLink(false);
                     }
                  }}
               />
            )}
         </div>
         <div className="flex items-center gap-1 justify-end mt-2 text-sm text-[#88848D]">
            Press Enter
            <span className="rotate-180">
               <Shortcut fontSize="small" />
            </span>
         </div>
      </div>
   );
};

export default InputQuestion;
