/** @format */

import { useMemo } from "react";

const Header = ({ fluentcontentView32Filled, heading2CustomizeTheInfoY, sayGoodbyeToLosingYourTra, propOverflow }) => {
   const headerStyle = useMemo(() => {
      return {
         overflow: propOverflow,
      };
   }, [propOverflow]);

   return (
      <div
         className="rounded-xl h-full box-border p-[1rem] text-[1rem] flex flex-col justify-start text-left gap-3 w-full bg-[#F6F5F4]"
         style={headerStyle}>
         <img
            className="w-8 relative h-8 overflow-hidden shrink-0"
            alt=""
            src={fluentcontentView32Filled}
         />
         <b className="text-[1.2rem]">{heading2CustomizeTheInfoY}</b>
         <div className="self-stretch relative md:text-lg tracking-[0.01px] text-[1rem] leading-[140%] text-gray-200">{sayGoodbyeToLosingYourTra}</div>
      </div>
   );
};

export default Header;
