/** @format */

import axios from "axios";
import { baseURL } from "../definitions";

export const readRequest = async (url, access_token_override = localStorage.getItem('access_token')) => {
   try {
      const response = await axios.get(`${baseURL}/${url}`, {
         headers: {
            Authorization: `Bearer ${access_token_override}`,
            "ngrok-skip-browser-warning": "true",
         },
      });
      return response;
   } catch (error) {
      console.log(error);
      return error.response;
   }
};

export const createRequest = async (url, data, access_token_override = localStorage.getItem('access_token')) => {
   try {
      const response = await axios.post(`${baseURL}/${url}`, data, {
         headers: {
            Authorization: `Bearer ${access_token_override}`,
            "ngrok-skip-browser-warning": "true",
         },
      });
      console.log(response.data, "RESPONSE FROM CREATE REQUEST");
      return response;
   } catch (error) {
      console.log(error);
      return error.response;
   }
};

export const updateRequest = async (url, data) => {
   try {
      const response = await axios.put(`${baseURL}/${url}`, data, {
         headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            "ngrok-skip-browser-warning": "true",
         },
      });
      return response;
   } catch (error) {
      console.log(error);
      return error.response;
   }
};

export const deleteRequest = async (url) => {
   try {
      const response = await axios.delete(`${baseURL}/${url}`, {
         headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            "ngrok-skip-browser-warning": "true",
         },
      });
      return response;
   } catch (error) {
      console.log(error);
      return error.response;
   }
};
