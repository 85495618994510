/** @format */

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExploreIcon from "@mui/icons-material/Explore";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import AskPng from "../../assets/Landingpage/AskPng.png";
import ExplorePng from "../../assets/Landingpage/ExplorePng.png";
import NotesPng from "../../assets/Landingpage/NotesPng.png";
import DownloadPng from "../../assets/Landingpage/DownloadPng.png";

const Property1Ask = ({ image799 }) => {
   const [selectSection, setSelectSection] = useState("ASK");
   const [visible, setVisible] = useState(false);
   const innerWidth = window.innerWidth;

   const worksData = [
      {
         title: "Ask",
         icon: <AutoAwesomeIcon />,
         type: "ASK",
         image: AskPng,
      },
      {
         title: "Explore",
         icon: <ExploreIcon />,
         type: "EXPLORE",
         image: ExplorePng,
      },
      {
         title: "Notes",
         icon: <MenuBookIcon />,
         type: "NOTES",
         image: NotesPng,
      },
      {
         title: "Download",
         icon: <DownloadForOfflineOutlinedIcon />,
         type: "DOWNLOAD",
         image: DownloadPng,
      },
   ];

   const selectedImage = worksData.find((item) => item.type === selectSection)?.image;

   const handleNext = () => {
      const currentIndex = worksData.findIndex((item) => item.type === selectSection);
      const nextIndex = (currentIndex + 1) % worksData.length;
      setSelectSection(worksData[nextIndex].type);
   };

   const handlePrev = () => {
      const currentIndex = worksData.findIndex((item) => item.type === selectSection);
      const prevIndex = (currentIndex - 1 + worksData.length) % worksData.length;
      setSelectSection(worksData[prevIndex].type);
   };

   return (
      <div className="flex flex-col">
         <div className="lg:max-w-full w-full flex flex-col items-center justify-start gap-[40px] text-center text-5xl text-darkgray font-inter-28px-bold">
            <div className="flex max-lg:w-[100%]  flex-row items-start lg:justify-start justify-between  lg:gap-[24px]">
               {worksData.map((item, index) => (
                  <div
                     key={index}
                     onClick={() => setSelectSection(item.type)}
                     className={`flex flex-col cursor-pointer items-center justify-center py-0 lg:px-6  lg:gap-[4px] ${selectSection === item.type && "text-[#000]"}`}>
                     <item.icon.type sx={{ color: selectSection === item.type && "#9D34DA" }} />
                     <div className="relative tracking-[-0.44px] leading-[30px] font-semibold max-lg:text-[1rem]">{item.title}</div>
                  </div>
               ))}
            </div>
         </div>
         <div className="relative w-[100%] h-[100%] mx-auto flex items-center mt-[2rem]">
            {innerWidth > 768 && (
               <ArrowBackIosIcon
                  onClick={handlePrev}
                  className="cursor-pointer absolute z-20 left-0 bg-[#F6F5F4] p-[1rem] rounded-full"
               />
            )}
            <img
               onLoad={() => setVisible(true)}
               data-aos="zoom-in"
               className="w-full h-full"
               src={selectedImage}
               alt="Selected Section"
            />
            {innerWidth > 768 && (
               <ArrowForwardIosIcon
                  onClick={handleNext}
                  className="cursor-pointer absolute z-20 right-0 bg-[#F6F5F4] p-[1rem] rounded-full"
               />
            )}
         </div>
      </div>
   );
};

export default Property1Ask;
