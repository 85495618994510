/** @format */

import React from "react";
import DoneIcon from "@mui/icons-material/Done";

const PricingCard = ({ price, type, pointOne, pointTwo, pointThree, pointFour }) => {
   return (
      <div
         style={{ boxShadow: type === "Pro" ? "0 3px 7px #D8EDE0" : "0 3px 7px rgba(0, 0, 0, 0.1)" }}
         className="rounded-2xl hover:scale-105 cursor-pointer transition-all duration-100 relative 2xl:w-[14rem] xl:px-[2rem] md:py-[2rem] p-[0.5rem] shadow-[0_3px_7px_rgb(0,0,0,0.1)]">
         {type === "Pro" && (
            <span
               style={{ borderBottomRightRadius: "0px", borderTopLeftRadius: "0px" }}
               className="bg-[#D8EDE0] absolute top-0 right-0 text-[#28A745] md:text-[1rem] text-[0.6rem] md:p-[0.5rem] p-[0.3rem] rounded-2xl">
               Recommended
            </span>
         )}
         <p className="md:text-[2rem] text-[1.2rem] font-bold max-sm:mt-[1rem] ">{type}</p>
         <p className="text-[1rem] font-semibold">
            <span className="md:text-[3rem] text-[1.4rem]  font-bold">${price}</span> /month
         </p>
         <div className="md:text-[1.1rem] text-[0.8rem] text-[#7F7D82] md:mt-[2rem] mt-[1rem] flex flex-col">
            <p className="flex items-start text-left justify-start gap-1 md:my-3 my-2">
               <DoneIcon sx={{ color: "green", fontSize: "1rem" }} />
               {pointOne}
            </p>
            <p className="flex items-start text-left justify-start gap-1 md:my-3 my-2">
               <DoneIcon sx={{ color: "green", fontSize: "1rem" }} />
               {pointTwo}
            </p>
            <p className="flex items-start text-left justify-start gap-1 md:my-3 my-2">
               <DoneIcon sx={{ color: "green", fontSize: "1rem" }} />
               {pointThree}
            </p>
            {pointFour && (
               <p className="flex items-start text-left justify-start gap-1 md:my-3 my-2">
                  <DoneIcon sx={{ color: "green", fontSize: "1rem" }} />
                  {pointFour}
               </p>
            )}
         </div>
      </div>
   );
};

export default PricingCard;
