/** @format */

import Features from "../../components/LandingPage/Features";
import Footer from "../../components/LandingPage/Footer";
import FrameComponent from "../../components/LandingPage/Footer";
import HeroSection from "../../components/LandingPage/HeroSection";
import FrameComponent1 from "../../components/LandingPage/HeroSection";
import Header from "../../components/LandingPage/Header";
import HowItWorks from "../../components/LandingPage/HowItWorks";
import Logo from "../../components/LandingPage/Logo";
import PricingContainer from "../../components/LandingPage/PricingContainer";
import Property1Ask from "../../components/LandingPage/Property1Ask";
import Pricing from "../../components/LandingPage/Pricing";
import "aos/dist/aos.css";
import AOS from "aos";
import { useEffect } from "react";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
   const innerWidth = window.innerWidth;
   const navigate = useNavigate();

   const handleNavigate = () => {
      navigate(`/login`);
   };

   useEffect(() => {
      AOS.init({
         disable: false,
         duration: 1000,
         once: true,
         offset: 100,
      });
   }, []);

   return (
      <div className="w-full relative flex flex-col items-center bg-new-version-white-100 h-full text-left text-xl text-gray-300 font-inter-28px-bold">
         <div className="w-full lg:p-5 flex lg:h-[120px] h-[70px] lg:px-10 flex-row items-center justify-between box-border">
            <div className="max-lg:w-full cursor-pointer px-[1rem] flex flex-row items-center lg:justify-start justify-between lg:gap-[80px] opacity-[0.8]">
               <Logo
                  typelessLogo="/typeless-logo@2x.png"
                  logoPosition="relative"
                  typeColor="#110a1c"
               />
               <div className="flex flex-row items-start justify-start lg:gap-[40px] gap-[1rem]">
                  <a
                     href="#features"
                     className="relative hover:text-[#000] transition-all duration-300 leading-[31px] max-lg:text-[0.9rem] no-underline text-[#373737ce] cursor-pointer font-medium">
                     Features
                  </a>
                  <a
                     href="#pricing"
                     className="relative hover:text-[#000] transition-all duration-300 leading-[31px] max-lg:text-[0.9rem] no-underline text-[#373737ce] decoration-current cursor-pointer font-medium">
                     Pricing
                  </a>
               </div>
            </div>
            {innerWidth > 1020 && (
               <button
                  onClick={handleNavigate}
                  className="bg-[#000] cursor-pointer hover:scale-105 active:scale-100 transition-all duration-300 flex justify-center items-center gap-2 text-white text-[1.3rem] px-[1.5rem] py-[0.9rem] rounded-md border-0 font-medium !outline-none">
                  Get started for free <EastIcon />
               </button>
            )}
         </div>
         <HeroSection />
         <div className="flex  lg:w-[80%] w-[90%] flex-col items-center justify-center md:gap-[80px] gap-[2rem] text-center text-45xl text-black">
            <HowItWorks />
            <Features />
            <Pricing />
         </div>
         <Footer />
      </div>
   );
};

export default LandingPage;
