/** @format */
import EastIcon from "@mui/icons-material/East";
import HeroImage from "../../assets/Landingpage/HeroImageMobile.png";
import HeroImageWeb from "../../assets/Landingpage/HeroImage.png";
import LeftStyling from "../../assets/Landingpage/HomeLeftStyling";
import RightStyling from "../../assets/Landingpage/HomeRightStyling";
import HomeLeftStylingMobile from "../../assets/Landingpage/HomeLeftStylingMobile";
import HomeRightStylingMobile from "../../assets/Landingpage/HomeRightStylingMobile";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
   const innerWidth = window.innerWidth;
   const navigate = useNavigate();

   const handleNavigate = () => {
      navigate(`/login`);
   };
   return (
      <div
         id="home"
         className="w-full relative h-full overflow-hidden flex flex-col items-center justify-center lg:gap-10 text-center text-61xl text-black font-inter-28px-bold">
         <span className="absolute top-[12%] left-0">{innerWidth > 1110 ? <LeftStyling /> : <HomeLeftStylingMobile />}</span>
         <span className="absolute top-[6%] right-0">{innerWidth > 1110 ? <RightStyling /> : <HomeRightStylingMobile />}</span>
         <div className="lg:min-h-[60vh] mt-[2.5rem] gap-5 flex flex-col w-full items-center h-full justify-center p-2">
            <div className="relative lg:text-[4rem] max-lg:text-[2.3rem] tracking-[-1px] leading-[100%] font-semibold">Discover, Explore,{innerWidth < 1020 && <br />} Learn</div>
            <div className="self-stretch relative lg:text-11xl text-[1rem] text-black leading-[140%] font-medium">
               Unlock the Power of Effortless Research with Seamless AI <br /> Assistance and Note-Taking
            </div>
            {innerWidth > 1020 ? (
               <button
                  onClick={handleNavigate}
                  className="bg-secondary-100 cursor-pointer hover:scale-105 active:scale-100 transition-all duration-300 mt-2 flex justify-center items-center gap-2 text-white text-[1.5rem] px-[2.5rem] py-[1rem] rounded-md border-0 font-medium !outline-none">
                  Get started for free <EastIcon />
               </button>
            ) : (
               <button
                  onClick={handleNavigate}
                  className="bg-secondary-100 cursor-pointer hover:scale-105 active:scale-100 transition-all duration-300 flex justify-center items-center gap-2 text-white text-[1.1rem] px-[1rem] py-[0.7rem] rounded-xl border-0 font-medium !outline-none">
                  Get started for free <EastIcon />
               </button>
            )}
         </div>
         <div className="h-full w-full">
            {innerWidth < 1020 ? (
               <img
                  src={HeroImage}
                  alt="image"
               />
            ) : (
               <div className="w-[100%]">
                  <img
                     src={HeroImageWeb}
                     className="h-full w-full"
                     alt=""
                  />
               </div>
            )}
         </div>
      </div>
   );
};

export default HeroSection;
