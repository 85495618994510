/** @format */

// import Logo from "../Logo";
// import logo from '../../assets/images/android-chrome-512x512.png'
import Logo from "../Logo";
import { useEffect, useState } from "react";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import CreateIcon from "@mui/icons-material/Create";
import LogoutIcon from "@mui/icons-material/Logout";
import { InputBase, Snackbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { createRequest, readRequest, updateRequest } from "../../utils/crud";
import { AdminPanelSettingsOutlined, LocalLibraryOutlined, MonetizationOnOutlined } from "@mui/icons-material";

const defaultMenu = [
   {
      name: "Dashboard",
      icon: <LocalLibraryOutlined />,
      link: "/dashboard",
   }, {
      name: "Credits",
      icon: <MonetizationOnOutlined />,
      link: "/credits",
   }
]

const Sidebar = ({ menu=defaultMenu }) => {

   const [active, setActive] = useState(localStorage.getItem("activeSidebar") || 0);
   const [editingName, setEditingName] = useState(false);
   const [accountDeets, setAccountDeets] = useState({});
   const [sidebarOpen, setSidebarOpen] = useState(true);
   const [snackbarOpen, setSnackbarOpen] = useState(false);

   const location = useLocation();
   const navigate = useNavigate();

   const getProfile = async () => {
      let res = await readRequest("api/v1/users/");
      console.log(res?.data, "res.data");
      if (res?.status === 200 || res?.status === 201) {
         setAccountDeets({
            profile_name: res?.data?.profile_name,
            profile_pic: res?.data?.profile_picture_url,
            admin: res?.data?.admin
         });
      } else if (res?.status === 401) {
         if (localStorage.getItem("access_token")) {
            res = await readRequest("api/v1/users/", localStorage.getItem("access_token"));
            if (res?.status === 200 || res?.status === 201) {
               setAccountDeets({
                  profile_name: res?.data?.profile_name,
                  profile_pic: res?.data?.profile_picture_url,
                  admin: res?.data?.admin
               });
            }
         }
      }
   };
   const handlePic = async (e) => {
      setAccountDeets({
         ...accountDeets,
         profile_pic: URL.createObjectURL(e.target.files[0])
      })
      const form = new FormData();
      form.append('profile_pic', e.target.files[0])
      const res = await createRequest('api/v1/users/profile_pic', form);
      if (!res?.status === 200 && !res?.status === 201){
         setAccountDeets({
            ...accountDeets,
            profile_pic: null
         })
      } else {
         setSnackbarOpen(true);
      }
   }
   useEffect(() => {
      getProfile();
   }, []);

   return (
      <div className={"flex flex-col justify-start h-screen gap-4 p-4 transition-all bg-white" + (sidebarOpen ? " w-64" : " w-20")}>
         <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
            message="Profile updated successfully"
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
         />

         <div className="flex justify-center w-full items-center">
            {sidebarOpen && <Logo className="mr-10" />}
            <ExpandCircleDownOutlinedIcon
               className={"cursor-pointer transition-all" + (sidebarOpen ? " rotate-90" : " rotate-[270deg]")}
               onClick={() => setSidebarOpen(!sidebarOpen)}
            />
         </div>
         {sidebarOpen && (
            <div className="flex justify-center items-center flex-col my-10">
               <div className="font-bold text-[#1877F2] text-xl rounded-full h-20 w-20 flex justify-center items-center bg-[#E8F1FE] relative transition-all cursor-pointer">
                  <div className="absolute w-full h-full rounded-full top-0 left-0 bg-[#110A1C]/[0.5] flex justify-center items-center opacity-0 hover:opacity-100 transition-all">
                     <CreateIcon
                        className="text-[#D9D9D9] cursor-pointer"
                        fontSize="large"
                     />
                     <input
                        type="file"
                        accept="image/*"
                        alt="profile pic"
                        className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                        onChange={handlePic}
                     />
                  </div>
                  {accountDeets?.profile_pic ? (
                     <img
                        src={accountDeets?.profile_pic}
                        alt="Your profile"
                        className="rounded-full h-20 w-20"
                     />
                  ) : (
                     accountDeets?.profile_name?.split(" ").map((name, index) => name[0]).join("")
                  )}
               </div>
               <div className="flex justify-center items-center">
                  <div>
                     {editingName ? (
                        <InputBase
                           autoFocus
                           value={accountDeets?.profile_name}
                           onChange={(e) => setAccountDeets({ ...accountDeets, profile_name: e.target.value })}
                           onBlur={() => setEditingName(false)}
                           onKeyDown={async (e) => {
                              if (e.key === "Enter") {
                                 if (accountDeets?.profile_name) {
                                    const res = await updateRequest("api/v1/users/", {
                                       profile_name: accountDeets?.profile_name,
                                    });
                                    setSnackbarOpen(true);
                                    console.log(res.data, "res.data");
                                 }
                                 setEditingName(false);
                              }
                           }}
                           sx={{
                              color: "#110A1C",
                              fontSize: "1.125rem",
                              fontWeight: "bold",
                              width: "fit-content",
                              textAlignLast: "center",
                           }}
                        />
                     ) : (
                        <div className="text-[#110A1C] font-bold text-lg cursor-pointer mt-1">{accountDeets?.profile_name}</div>
                     )}
                  </div>
                  {!editingName && (
                     <CreateIcon
                        className="ml-2 text-[#1877f2] cursor-pointer"
                        fontSize="small"
                        onClick={() => setEditingName(true)}
                     />
                  )}
               </div>
            </div>
         )}
         { menu?.map((item, index) => (
            <div key={index} className={'flex justify-start w-full items-center p-3 rounded-lg font-bold cursor-pointer hover:bg-[#FDF5F6] transition-all' + (active == index ? ' text-[#110A1C] bg-[#000]/[.04]' : ' text-[#88848D]')} onClick={() => {
               setActive(index);
               localStorage.setItem("activeSidebar", index);
               navigate(item.link);
            }}>
               <div className="mr-2">
                  {item.icon}
               </div>
               <div>
                  {sidebarOpen && item.name}
               </div>
            </div>
         ))}
         {(accountDeets?.admin && location.pathname !== "/admin") && (
            <div className={'flex justify-start w-full items-center p-3 rounded-lg font-bold cursor-pointer hover:bg-[#FDF5F6] transition-all' + (active == menu.length ? ' text-[#110A1C] bg-[#000]/[.04]' : ' text-[#88848D]')} onClick={() => {
               setActive(menu.length);
               localStorage.setItem("activeSidebar", menu.length);
               navigate("/admin");
            }}>
               <div className="mr-2">
                  <AdminPanelSettingsOutlined />
               </div>
               <div>
                  {sidebarOpen && "Admin"}
               </div>
            </div>
         )}            
         <div
            className={"flex justify-start w-full items-center p-3 rounded-lg font-bold cursor-pointer mt-auto text-[#D90429] bg-[#FDF5F6]"}
            onClick={() => {
               localStorage.removeItem("access_token");
               navigate("/");
            }}>
            <LogoutIcon className="mr-2" />
            <div>{sidebarOpen && "Logout"}</div>
         </div>
      </div>
   );
};

export default Sidebar;
