import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="221"
      fill="none"
      viewBox="0 0 92 221"
    >
      <path
        stroke="#8BBBF8"
        strokeWidth="2"
        d="M17.919 32.938c-5.547 12.34-14.181 38.947-4.341 46.642 12.3 9.619 35.634.363 76.513-46.642 32.704-37.605-3.075-34.06-15.556-28.494-12.48 5.565-32.92 16.261-64.032 66.788-8.984 20.387-19.608 62.177 9.767 66.243 36.72 5.081 88.356-55.713 77.78-72.233-10.575-16.52-60.957 26.312-72.715 58.621-11.757 32.31 0 82.219 45.764 66.969 45.763-15.249 70.628-73.679 74.885-87.114 4.257-13.435-4.667-19.928-30.569 3.448-32.378 29.22-49.2 74.773-44.316 96.189 4.883 21.416 26.047 17.06 29.664 14.338 14.652-7.078 39.071-30.49 45.221-30.49"
        opacity="0.5"
      ></path>
    </svg>
  );
}

export default Icon;
