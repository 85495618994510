import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      fill="none"
      viewBox="0 0 48 49"
    >
      <rect width="48" height="48" y="0.5" fill="#fff" rx="24"></rect>
      <g clipPath="url(#clip0_784_10187)">
        <path
          fill="#000"
          d="M31.04 31.541h-2.962v-4.64c0-1.107-.02-2.532-1.542-2.532-1.543 0-1.78 1.206-1.78 2.45v4.722h-2.963v-9.544h2.845v1.305h.04a3.118 3.118 0 012.807-1.542c3.003 0 3.557 1.976 3.557 4.546l-.001 5.235zM18.45 20.693a1.72 1.72 0 110-3.441 1.72 1.72 0 010 3.44zM19.93 31.54h-2.966v-9.544h2.966v9.544zm12.587-17.04H15.476A1.46 1.46 0 0014 15.943v17.114a1.46 1.46 0 001.476 1.443h17.042A1.464 1.464 0 0034 33.057V15.942a1.462 1.462 0 00-1.482-1.442"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_784_10187">
          <rect
            width="20"
            height="20"
            x="14"
            y="14.5"
            fill="#fff"
            rx="1.905"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
