/** @format */

import { useMemo } from "react";
import MobileLogo from "../../assets/Landingpage/TypeLessLogoMobile";

const Logo = ({ typelessLogo, logoPosition, typeColor }) => {
   const logoStyle = useMemo(() => {
      return {
         position: logoPosition,
      };
   }, [logoPosition]);

   const typeStyle = useMemo(() => {
      return {
         color: typeColor,
      };
   }, [typeColor]);

   const innerWidth = window.innerWidth;

   return (
      <>
         {innerWidth > 1020 ? (
            <div
               className="w-[182px] h-[50px] text-left text-9xl text-text-100 font-inter-28px-bold"
               style={logoStyle}>
               <b className="absolute top-[8%] left-[30.77%] leading-[42px]">
                  <span style={typeStyle}>type</span>
                  <span className="text-secondary-100">Less</span>
               </b>
               <img
                  className="absolute h-[96%] w-[26.37%] top-[2%] right-[73.63%] bottom-[2%] left-[0%] max-w-full overflow-hidden max-h-full object-contain"
                  alt=""
                  src={typelessLogo}
               />
            </div>
         ) : (
            <MobileLogo />
         )}
      </>
   );
};

export default Logo;
