/** @format */

import React from "react";
import Property1Ask from "./Property1Ask";
import UnderlineDesign from "../../assets/Landingpage/UnderlineDesign.png";

const HowItWorks = () => {
   const innerWidth = window.innerWidth;
   return (
      <>
         <div className="flex w-full flex-col items-center justify-start gap-[24px] text-[32px] font-georgia">
            <span
               data-aos={`${innerWidth > 768 ? "zoom-out" : ""}`}
               className=" italic !overflow-hidden md:text-[1rem] text-[0.9rem]">
               “Where Questions Spark Answers and Ideas Flow Freely”
            </span>
            {innerWidth < 1020 ? (
               <div className="w-[13rem]">
                  <img
                     className="w-full "
                     src={UnderlineDesign}
                     alt="quote"
                  />
               </div>
            ) : (
               <img
                  className="w-[613px] relative h-[50px] overflow-hidden shrink-0 object-cover"
                  alt=""
                  src="/toolsstrikethroughv2png@2x.png"
               />
            )}
         </div>
         <div className="flex w-full flex-col items-center justify-start gap-[80px]">
            <div className="flex flex-col w-full items-center justify-start gap-[20px]">
               <img
                  className="w-[100px] relative h-[65.4px] "
                  alt=""
                  src="/gear.svg"
               />
               <div className="relative tracking-[-1px] max-lg:text-[2.5rem] leading-[100%] font-semibold">How it works?</div>
            </div>
            <div className="flex w-full flex-row items-center justify-center">
               <Property1Ask image799="/image-799@2x.png" />
            </div>
         </div>
      </>
   );
};

export default HowItWorks;
