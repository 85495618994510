import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="187"
      height="608"
      fill="none"
      viewBox="0 0 187 608"
    >
      <path
        stroke="#8BBBF8"
        strokeWidth="4"
        d="M48.767 89.987c-15.333 34.001-39.2 107.301-12 128.501 34 26.5 98.5 1 211.5-128.5 90.4-103.6-8.5-93.834-43-78.5-34.5 15.333-91 44.8-177 184-24.833 56.166-54.2 171.299 27 182.499 101.5 14 244.233-153.487 215-199-29.232-45.512-168.5 72.488-201 161.5-32.5 89.013 0 226.513 126.5 184.5 126.5-42.012 195.233-202.987 207-240 11.768-37.012-12.9-54.9-84.5 9.5-89.5 80.5-136 206-122.5 265s72 47 82 39.5c40.5-19.5 108-84 125-84"
      ></path>
    </svg>
  );
}

export default Icon;
