/** @format */

import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { deleteRequest } from "../../utils/crud";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";

const TrackCard = ({ track, key, tracks, setTracks, getRemainingTracks, setLoading, searchString }) => {
   const navigate = useNavigate();
   const confirm = useConfirm();

   const deleteTrack = async () => {
      confirm({
         title: "Are you sure?",
      })
         .then(async () => {
            setLoading(true)
            const res = await deleteRequest(`api/v1/tracks/${track.track_id}`);
            if (res.status === 200) {
               setTracks(tracks.filter((t) => t.track_id !== track.track_id));
            }
            setLoading(false)
            getRemainingTracks();
         })
         .catch((err) => { console.log(err); setLoading(false); });
   };

   const date = new Date(track?.created_at);

   const titleCopy = track?.title
   const i = track?.title?.toLowerCase().indexOf(searchString?.toLowerCase())
   
   return (
      <div
         className="bg-white rounded-2xl p-4  gap-2 flex flex-col justify-between items-stretch text-start relative cursor-pointer shadow-md"
         key={key}
         onClick={() => navigate(`/answer`, { state: track })}>
         {/* <div className="bg-[#110A1C] h-full w-2 absolute top-0 left-0 rounded-s-2xl "></div> */}
         <div className="grow flex items-start">
            <div className="w-11/12">
               {searchString && 
                  <>
                     {titleCopy.slice(0, i)}
                     <span className="bg-yellow-300">{titleCopy.slice(i, i + searchString.length)}</span>
                     {titleCopy.slice(i + searchString.length)}
                  </>
               }
               {!searchString && <div className="truncate">{track?.title}</div>}
            </div>
            <div className="ml-auto">
               <DeleteOutlineOutlined
                  onClick={(e) => {
                     e.stopPropagation();
                     deleteTrack();
                  }}
                  className="text-error cursor-pointer z-10"
               />
            </div>
         </div>
         <div className="text-[#88848D] text-sm">
            <div>{track?.questions_count || 0} questions</div>
            <div className="mt-1">
               Last updated on{" "}
               {date.toLocaleString("en-US", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
               })}
            </div>
         </div>
      </div>
   );
};

export default TrackCard;
