import youtube_searched_for from './../../assets/images/youtube_searched_for.png'
import logoImage from './../../assets/images/android-chrome-512x512.png'
const Logo = ({className}) => {
    return (
        <div className={'flex justify-center items-center ' + className}>
            <div className="inline-block h-10 w-10 rounded-full overflow-hidden">
                <img src={logoImage} alt="Logo" className="" />
            </div>
            <div className="text-2xl font-bold ml-2">
                type
            </div>
            <div className="text-2xl font-bold text-[#1877F2]">
                Less
            </div>
        </div>
    )
}

export default Logo