/** @format */

import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InputBase, Snackbar, Alert, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import InputQuestion from "../../components/InputQuestion";
import { createRequest, updateRequest } from "../../utils/crud";
import { AutoAwesome } from "@mui/icons-material";
import Loader from "../../components/Loader";

const CreateTrack = () => {
     const location = useLocation();

     const [track, setTrack] = useState({
          title: location?.state?.source === "file" ? `Exploring ${location?.state?.fileUpload?.filename} file` : "",
          track_file: location?.state?.fileUpload?.file,
          track_source: location?.state?.source,
          file_name: location?.state?.fileUpload?.filename,
     });
     const [isEnter, setEnter] = useState(false);
     const navigate = useNavigate();
     const [snackbarOpen, setSnackbarOpen] = useState(false);
     const [snackbarMessage, setSnackbarMessage] = useState("");
     const [loading, setLoading] = useState(false);
     const createTrack = async () => {
          if (loading) return;
          if (track?.track_id) {
               setLoading(true);
               const res = await updateRequest(`api/v1/tracks/${track.track_id}`, {
                    title: track.title || "Untitled",
                    // track_file: track.track_file,
                    // track_source: track.track_source
               });
               if (res?.status === 200 || res?.status === 201) {
                    setLoading(false);
                    return res?.data;
               } else {
                    setLoading(false);
                    setSnackbarOpen(true);
                    setSnackbarMessage("Something went wrong. Please try again.");
               }
          } else {
               const form = new FormData();
               form.append("title", track.title || "Untitled");
               if (track?.track_file) {
                    form.append("track_file", track.track_file);
               }
               if (track?.track_source) {
                    form.append("track_source", track.track_source);
               }
               const response = await createRequest("api/v1/tracks/", form);
               if (response?.status === 200 || response?.status === 201) {
                    setTrack({ ...track, title: response?.data?.title, track_id: response?.data?.track_id, ...response?.data });
                    setLoading(false);
                    return response?.data;
               } else if (response?.status === 500) {
                    navigate("/dashboard", { state: { error: "Something went wrong. Please try again." } })
               } else {
                    setLoading(false);
                    setSnackbarOpen(true);
                    setSnackbarMessage("Something went wrong. Please try again.");
               }
          }
     };

     const creatingApis = async () => {
          if (track?.questions[0]?.question) {
               localStorage.setItem("creatingQuestion", "incomplete");
               navigate("/answer", { state: track });
               const questionRes = await createRequest("api/v1/questions/", {
                    track_id: track?.track_id,
                    question: track?.questions[0]?.question,
                    question_idx: 0,
                    search_in_link: track?.questions[0]?.link || "",
                    created_from: "from_new_question_button",
                    hidden: false,
               });
               console.log(questionRes?.data, "questionRes.data");
               localStorage.setItem("creatingQuestion", questionRes?.data?.question_id);
               window.location.reload();
          }
     };

     const returnQuestion = async (question) => {
          await creatingApis();
     };

     useEffect(() => {
          createTrack()
     }, [])

     return (
          <div>
               <Loader loading={loading} />
               <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}>
                    <Alert
                         onClose={() => setSnackbarOpen(false)}
                         severity="error"
                         sx={{ width: "100%" }}>
                         {snackbarMessage}
                    </Alert>
               </Snackbar>

               <div className="flex items-center gap-2 justify-start">
                    <ArrowBackIcon
                         onClick={() => navigate("/dashboard")}
                         className="m-4 cursor-pointer"
                    />
               </div>
               <div className="w-4/5 mx-auto mt-24 flex flex-col">
                    <div className="flex items-center justify-between mb-24">
                         <InputBase
                              placeholder="Enter title of your journey"
                              name="title"
                              inputProps={{
                                   sx: {
                                        fontSize: "1.5rem",
                                        fontWeight: "bold",
                                   },
                              }}
                              autoFocus
                              value={track.title}
                              onKeyDown={async (e) => {
                                   console.log(e.key, "e.key");
                                   if (e.key === "Enter") {
                                        setEnter(true);
                                        e.preventDefault();
                                        e.target.blur();
                                   }
                              }}
                              onBlur={async () => {
                                   await createTrack();
                              }}
                              className="grow"
                              onChange={(e) => setTrack({ ...track, title: e.target.value })}
                         />
                         <div className="w-max">
                              <AutoAwesome
                                   htmlColor="#FDC500"
                                   fontSize="small"
                                   className="mr-2"
                              />
                              {loading ? "Preparing your pdf, " : "Fetching answers from"} {location?.state?.source === "internet" ? "the internet" : location?.state?.fileUpload?.filename}
                         </div>
                    </div>
                    <InputQuestion
                         isEnter={isEnter}
                         setEnter={setEnter}
                         returnQuestion={returnQuestion}
                         track={track}
                         setTrack={setTrack}
                    />
               </div>
          </div>
     );
};

export default CreateTrack;
