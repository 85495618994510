import { StyleSheet } from "@react-pdf/renderer";

const PDFstyles = StyleSheet.create({
    page: {
       backgroundColor: '#f5f5f5',
       padding: 10,
    },
    question: {
       margin: 10,
       marginTop: 0,
       padding: 10,
       border: '1px solid black',
    },
    questionTitle: {
       fontStyle: 'bold',
       fontSize: 20,
       marginBottom: 10,
    },
    note: {
       marginTop: 10,
       marginBottom: 10,
    },
    reference: {
       marginTop: 10,
       fontStyle: 'bold',
       textDecoration: 'underline',
    }
 });

 export default PDFstyles;