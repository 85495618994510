import logo from './logo.svg';
import './App.css';
import Login from './pages/Login';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Onboarding from './pages/Onboarding';
import { createTheme, ThemeProvider } from '@mui/material';
import Dashboard from './pages/Dashboard';
import CreateTrack from './pages/CreateTrack';
import Answer from './pages/Answer';
import { ConfirmProvider } from 'material-ui-confirm';
import Admin from './pages/Admin';
import Credits from './pages/Credits';
import SuccessPayment from './pages/SuccessPayment';
import CancelledPayment from './pages/CancelledPayment';
import ForgotPassword from './pages/ForgotPassword';
import Settings from './pages/Settings';
import CookiePolicies from './pages/CookiePolicies';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { clientId } from './definitions';
import LandingPage from './pages/LandingPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#110A1C',
      light: '#1E1A2F',
      dark: '#0A0512'
    },
    secondary: {
      main: '#1877F2',
      light: '#4C9AFF',
      dark: '#004ACB'
    },
    yellow: {
      main: '#FBBC05',
      light: '#FDD835',
      dark: '#F9A825'
    }
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.use === 'pill') && {
            borderRadius: 9999,
            padding: '0.3rem 1.3rem',
            border: '1px solid #D6D9DE',
            '&.Mui-selected': {
              backgroundColor: '#1E1A2F',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#1E1A2F',
                color: '#fff',
              }
            },
            '&:hover': {
              backgroundColor: '#1E1A2F',
              color: '#fff',
            }
          },
          ...(ownerState.use === 'source') && {
            borderRadius: '2rem',
            padding: '1rem 1.3rem',
            border: '1px solid #C3C2C6',
            backgroundColor: '#fff',
            color: '#110A1C',
            width: '50%',
            '&.Mui-selected': {
              outline: '2px solid #110A1C',
              backgroundColor: '#fff',
              color: '#110A1C',
              '&:hover': {
                backgroundColor: '#fff',
                color: '#110A1C',
              }
            },
            '&:hover': {
              backgroundColor: '#fff',
              color: '#110A1C',
            }
          }
        }),
      }
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.use === 'pill') && {
            display: 'grid',
            placeItems: 'start',
            gridTemplateColumns: 'repeat(3, auto)',
            gridGap: '1rem',
            width: 'fit-content',
          },
          ...(ownerState.use === 'source') && {
            gap: '2rem',
            width: '100%',
            ' > *': {
              width: '49%',
            }
          }
        }),
        grouped: ({ ownerState, theme }) => ({
          ...(ownerState.use === 'pill') && {
            '&:not(:first-child)': {
              borderLeft: '1px solid #D6D9DE',
              borderRadius: 9999,
            },
            '&:not(:last-child)': {
              borderRight: '1px solid #D6D9DE',
              borderRadius: 9999,
            },
          },
          ...(ownerState.use === 'source') && {
            '&:not(:first-child)': {
              borderLeft: '1px solid #C3C2C6',
              borderRadius: '1rem',
            },
            '&:not(:last-child)': {
              borderRight: '1px solid #C3C2C6',
              borderRadius: '1rem',
            },
          },
        }),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.use === 'indicator' && {
            boxShadow: 'none',
            borderRadius: 0,
          }),
          display: 'inline-flex',
          gap: '0.5rem',
        }),
        grouped: ({ ownerState, theme }) => ({
          ...(ownerState.use === 'indicator' && {
            '&:disabled': {
              width: '0',
              borderRadius: 9999,
              minWidth: '0',
              padding: '4px',
            },
            '&:not(:last-of-type)': {
              borderRadius: 9999,
              '&:disabled': {
                border: 'none',
              },
            },
            '&:not(:first-of-type)': {
              borderRadius: 9999,
              '&:disabled': {
                border: 'none',
              },
            },
            minWidth: '0'
          }),
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          color: '#88848D',
          fontSize: '0.8rem',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: '0.5rem',
          ...(ownerState.color === 'yellow' && {
            color: '#000',
          })
        }),
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '1rem',
        }
      }
    }
  },
  typography: {
    'button': {
      'textTransform': 'none',
    }
  }
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <GoogleOAuthProvider clientId={clientId}>
          <div className="App">
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<LandingPage />} />
                <Route path="/login" element={<Login page={'login'} />} />
                <Route path="/register" element={<Login page={'register'} />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/onboarding" element={<Onboarding />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create-track" element={<CreateTrack />} />
                <Route path="/answer" element={<Answer />} />
                <Route path="/admin" element={<Admin />} />
                <Route path='/credits' element={<Credits />} />
                <Route path='/success/:id' element={<SuccessPayment />} />
                <Route path='/cancelled/:id' element={<CancelledPayment />} />
                <Route path='/settings' element={<Settings />} />
                <Route path="cookie-policies" element={<CookiePolicies />} />
              </Routes>
            </BrowserRouter>
          </div>
        </GoogleOAuthProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
