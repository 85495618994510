import { useStreamExample } from '@llm-ui/react'
import React from 'react'


const LLMOutput = ({
    data
}) => {

    const Str = data?.map((item) => item).join('')
    const { output } = useStreamExample(Str, {
        autoStart: true,
        autoStartDelayMs: 1000,
        startIndex: 0,
        delayMultiplier: 0.4,
    });

    return (
        <div>
            {output}
        </div>
    )
}

export default LLMOutput
