/** @format */

import React from "react";
import Header from "./Header";

const Features = () => {
   return (
      <div
         id="features"
         className="w-full flex flex-col items-center justify-start md:gap-[80px] gap-[3rem]">
         <div className="flex flex-col w-full items-center justify-start lg:gap-[40px] gap-[1rem]">
            <div className="flex flex-col items-center justify-start gap-[20px]">
               <img
                  className="lg:w-[100px] w-[3rem] h-[3rem] relative lg:h-[125.9px] animate-pulse"
                  alt=""
                  src="/frame-427318989.svg"
               />
               <div className="relative tracking-[-1px] leading-[100%] max-lg:text-[2.5rem] font-semibold">Features</div>
            </div>
            <div className="relative lg:text-5xl text-[1rem] font-medium">Experience seamless research with our essential features.</div>
         </div>
         <div className="w-[100%]">
            <div className="flex w-[100%] max-md:flex-col lg:gap-[2rem] gap-[1rem]">
               <div className="md:w-[50%]">
                  <Header
                     fluentcontentView32Filled="/fluentcontentview32filled.svg"
                     heading2CustomizeTheInfoY="File Upload"
                     sayGoodbyeToLosingYourTra={
                        <>
                           With our Document Upload feature, you can effortlessly extract valuable insights from your files.
                           <br />
                           <br />
                           Not only does Type Less extract insights from your uploaded documents, but it also generates related questions to facilitate further exploration and understanding of the
                           topic at hand.
                           <br />
                           <br />
                           This feature enhances your research process by providing a comprehensive view of the subject matter.
                        </>
                     }
                  />
               </div>
               <div className="flex md:w-[50%] flex-col lg:gap-[2rem] gap-[1rem]">
                  <Header
                     fluentcontentView32Filled="/fluentcontentview32filled.svg"
                     heading2CustomizeTheInfoY="Seamless Context Preservation"
                     sayGoodbyeToLosingYourTra="Say goodbye to losing your train of thought. TypeLess ensures that you never lose context while exploring your queries and suggestions."
                  />
                  <Header
                     fluentcontentView32Filled="/solarnotesbold.svg"
                     heading2CustomizeTheInfoY="Note-Taking Made Simple"
                     sayGoodbyeToLosingYourTra="Capture important insights and key points easily with our built-in note-taking feature. Stay organized and focused as you delve deeper into your research."
                     propOverflow="unset"
                  />
               </div>
            </div>
            <div className="flex max-md:flex-col lg:gap-[2rem] gap-[1rem] lg:mt-[2rem] mt-[1rem]">
               <Header
                  fluentcontentView32Filled="/iconoirbrainresearch.svg"
                  heading2CustomizeTheInfoY="Ideal for Research"
                  sayGoodbyeToLosingYourTra="Whether you're a student, a professional, or a curious mind, TypeLess is tailored to meet your research needs efficiently."
                  propOverflow="hidden"
               />
               <Header
                  fluentcontentView32Filled="/iconoirbrainresearch.svg"
                  heading2CustomizeTheInfoY="Ideal for Research"
                  sayGoodbyeToLosingYourTra="Whether you're a student, a professional, or a curious mind, TypeLess is tailored to meet your research needs efficiently."
                  propOverflow="hidden"
               />
            </div>
         </div>
      </div>
   );
};

export default Features;
