/** @format */

import PDFstyles from './PdfStyles';
import { PDFDownloadLink, Page, Text, View, Document, Link } from '@react-pdf/renderer';
import load from './../../assets/images/animation_llw26k69_small.gif'
import {
   VolumeUpOutlined,
   ArrowBack,
   OpenInNew,
   Download,
   ThumbDown,
   ThumbDownOutlined,
   ThumbUp,
   ThumbUpOutlined,
   ContentCopyOutlined,
   DescriptionOutlined,
   AddOutlined,
   CloseOutlined,
   Send,
   KeyboardVoice,
   SearchOutlined,
   VisibilityOutlined,
   VolumeOffOutlined,
   EditOutlined,
   AutoAwesome,
   Replay,
   Edit,
   Check,
   CheckOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { OutlinedInput, InputAdornment, Button, Chip, TextField, Input, Snackbar, InputBase, Tooltip, Menu } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import empty from "../../assets/images/empty.png";
import { useConfirm } from "material-ui-confirm";
import { useSpeechSynthesis } from "react-speech-kit";
import InputQuestion from "../../components/InputQuestion";
import { createRequest, readRequest, deleteRequest, updateRequest } from "../../utils/crud";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import AudioRecorder from "../../components/AudioRecorder/AudioRecorder";
import { baseURL } from "../../definitions";
import { useStreamExample } from '@llm-ui/react';
import LLMOutput from '../../components/LLMOutput';
import Loader from '../../components/Loader';


const Answer = () => {
   const location = useLocation();
   const navigate = useNavigate();
   const confirm = useConfirm();
   const { speak, speaking, cancel, supported, voices } = useSpeechSynthesis();

   const [track, setTrack] = useState(location?.state);
   useEffect(() => {
      setTrack(location?.state);
   }, [location]);

   const [showSuggestions, setShowSuggestions] = useState(true);
   const [updatingSuggestions, setUpdatingSuggestions] = useState(false);
   const [noOfSuggestedQuestions, setNoOfSuggestedQuestions] = useState(3);

   const [currentQuestion, setCurrentQuestion] = useState({});
   const [currentTrack, setCurrentTrack] = useState({});
   const [bufferQuestion, setBufferQuestion] = useState({}); // used when you hide current question
   const [loadingAnswerId, setLoadingAnswerId] = useState('new');

   const [loadingQuestion, setLoadingQuestion] = useState(false);

   const [viewHiddenQuestions, setViewHiddenQuestions] = useState(false);
   const [searchHiddenQuestions, setSearchHiddenQuestions] = useState("");

   const [editingNote, setEditingNote] = useState(-1);
   const [tmpNewNote, setTmpNewNote] = useState("");
   const [tmpEditingNote, setTmpEditingNote] = useState("");
   const [showNotes, setShowNotes] = useState(null);
   const [takingVoiceNote, setTakingVoiceNote] = useState(false);
   const [tmpNewVoiceNote, setTmpNewVoiceNote] = useState(null);

   const [linksExpanded, setLinksExpanded] = useState(false);

   const [copiedSnackbar, setCopiedSnackbar] = useState(false);

   const [addNewQuestion, setAddNewQuestion] = useState(false);

   const [editingTitle, setEditingTitle] = useState(false);
   const [tmpNewTitle, setTmpNewTitle] = useState("");

   const [loading, setLoading] = useState(true);

   const [downloadOptionsAnchor, setDownloadOptionsAnchor] = useState(null);

   const [editingQuestionId, setEditingQuestionId] = useState(null);
   const [tmpNewQuestion, setTmpNewQuestion] = useState("");
   const [emptyTmpNewQuestion, setEmptyTmpNewQuestion] = useState(false);

   let contextTrigger = null

   const toggleMenu = e => {
      if (contextTrigger) {
         contextTrigger.handleContextClick(e);
      }
   };

   const fetchData = async () => {
      setLoading(true);
      const res = await readRequest(`api/v1/questions/qandA/${track?.track_id}`);
      console.log("res?.data", res?.data);
      if (res?.status === 200 && res?.data) {
         setCurrentQuestion(res?.data[0]);

         // fetchNotes
         const res2 = await readRequest(`api/v1/notes/${track?.track_id}`);
         setLoading(false);
         console.log("res2?.data", res2?.data);
         if (res2?.status === 200 && res2?.data) {
            setCurrentTrack({
               title: track.title,
               questions: res?.data,
               notes: res2?.data,
            });
         }
      } else if (res?.status === 401 || res?.status === 403) {
         navigate("/");
         localStorage.removeItem("access_token");
      }
      if (localStorage.getItem("creatingQuestion") !== 'incomplete') {
         setLoadingAnswerId(null); // beacause it is true by default
         localStorage.removeItem("creatingQuestion");
      }
   };
   const createQuestion = async (question, search_in_link, question_idx, created_from) => {
      setCurrentTrack({
         ...currentTrack,
         questions: [
            ...(currentTrack.questions || []),
            {
               question,
               search_in_link,
               question_idx,
               created_from,
               track_id: track.track_id,
            },
         ],
      });
      setCurrentQuestion({ question, search_in_link, question_idx, created_from, track_id: track.track_id });
      setLoadingAnswerId("new");
      const res = await createRequest("api/v1/questions/", {
         question,
         track_id: track.track_id,
         created_from,
         search_in_link: search_in_link || "",
         question_idx,
         hidden: false
      });
      setLoadingAnswerId(null);
      if ((res?.status === 201 || res?.status === 200) && res?.data) {
         console.log("res?.data", res?.data);
         setCurrentTrack({
            ...currentTrack,
            questions: [...(currentTrack.questions || []), res?.data],
         });
         setCurrentQuestion(res?.data);
         return res?.data;
      }
   };
   const editQuestion = async (question_id, question, search_in_link, question_idx, created_from, hidden) => {
      setLoadingAnswerId(question_id);
      setCurrentTrack({
         ...currentTrack,
         questions: currentTrack.questions.map((q) => {
            if (q.question_id === question_id) {
               return {
                  ...q,
                  question,
                  search_in_link,
                  question_idx,
                  created_from,
                  hidden,
               };
            }
            return q;
         }),
      });
      const res = await updateRequest(`api/v1/questions/${question_id}`, {
         question,
         hidden,
         track_id: track.track_id,
         created_from,
         search_in_link: search_in_link || "",
         question_idx,
      });
      if (res?.status === 200 || res?.status === 201) {
         if (res?.data?.question_id === currentQuestion?.question_id) {
            setCurrentQuestion(res?.data);
         }
      }
      setLoadingAnswerId(null)
      console.log("res?.data", res?.data);
   };
   const reAskQuestion = async (question) => {
      setLoadingAnswerId(question?.question_id);
      const res = await updateRequest(`api/v1/questions/re_ask/${question?.question_id}`, {
         question: question?.question,
         hidden: question?.hidden,
         track_id: track.track_id,
         created_from: question?.created_from,
         search_in_link: question?.search_in_link || "",
         question_idx: question?.question_idx,
      })
      setLoadingAnswerId(null);
      if ((res?.status === 201 || res?.status === 200) && res?.data) {
         console.log("res?.data", res?.data);

         setCurrentQuestion(res?.data);
         return res?.data;
      }
   }




   const createNote = async (note, note_type, question_id) => {
      let formData = new FormData();
      formData.append("note_type", note_type);
      formData.append("question_id", question_id);
      formData.append("track_id", track.track_id);

      if (note_type === "voice") {
         formData.append("audio_file", note);
         formData.append("note", "");
      } else {
         formData.append("note", note);
      }

      const res = await createRequest("api/v1/notes/", formData);
      console.log("res?.data", res?.data);
      if ((res?.status === 201 || res?.status === 200) && res?.data) {
         setCurrentTrack({
            ...currentTrack,
            notes: [...(currentTrack.notes || []), res?.data],
         });
      }
   };
   const editNote = async (note_id, note, note_type, question_id) => {
      const res = await updateRequest(`api/v1/notes/${note_id}`, {
         note,
         note_type,
         question_id,
         track_id: track.track_id,
      });
      console.log("res?.data", res?.data);
      if ((res?.status === 201 || res?.status === 200) && res?.data) {
         setCurrentTrack({
            ...currentTrack,
            notes: currentTrack?.notes?.map((n) => {
               if (n.note_id === note_id) {
                  return res?.data;
               }
               return n;
            }),
         });
      }
   };
   const deleteNote = async (note_id) => {
      const res = await deleteRequest(`api/v1/notes/${note_id}`);
      console.log("res?.data", res?.data);
      if ((res?.status === 201 || res?.status === 200) && res?.data) {
         setCurrentTrack({
            ...currentTrack,
            notes: currentTrack?.notes?.filter((n) => n.note_id !== note_id),
         });
      }
   };
   const editTrack = async (track_id, title) => {
      const res = await updateRequest(`api/v1/tracks/${track_id}`, {
         title,
      });
      console.log("res?.data", res?.data);
      if ((res?.status === 201 || res?.status === 200) && res?.data) {
         setCurrentTrack({
            ...currentTrack,
            title: res?.data.title,
         });
      }
   };
   const editAnswer = async (question_id, answer_id, was_answer_useful) => {
      const res = await updateRequest(`api/v1/answers/${answer_id}`, {
         was_answer_useful,
      });
      console.log("res?.data", res?.data);
      if ((res?.status === 201 || res?.status === 200) && res?.data) {
         setCurrentTrack({
            ...currentTrack,
            questions: currentTrack.questions.map((q) => {
               if (q.question_id === question_id) {
                  return {
                     ...q,
                     answer_details: {
                        ...q.answer_details,
                        was_answer_useful,
                     },
                  };
               }
               return q;
            }),
         });
         setCurrentQuestion({
            ...currentQuestion,
            answer_details: {
               ...currentQuestion.answer_details,
               was_answer_useful,
            },
         });
      }
   };

   const returnQuestion = async (question) => {
      setAddNewQuestion(false);
      if (question.question) createQuestion(question?.question, question?.link || "", currentTrack?.questions?.length || 0, "from_new_question_button");
      console.log("return question", question);
   };
   const returnAudioNote = async (note) => {
      setTakingVoiceNote(false);
      if (note) {
         await createNote(note, tmpNewVoiceNote?.note_type, tmpNewVoiceNote?.question_id);
         const res = await readRequest(`api/v1/notes/${track?.track_id}`)
         if (res?.status === 200 && res?.data) {
            setCurrentTrack({
               ...currentTrack,
               notes: res?.data,
            });
         }
      }
      setTmpNewVoiceNote(null);
      console.log("return note", note);
   };

   useEffect(() => {
      fetchData();
   }, []);




   const NotesPDF = () => (
      <Document>
         <Page size="A4" style={PDFstyles.page}>
            {currentTrack?.questions?.map((question, i) => (
               (currentTrack?.notes?.filter((n) => n.question_id === question.question_id)?.length > 0 || question?.answer_details?.links?.length > 0) && (
                  <View key={question.question_id} style={PDFstyles.question}>
                     <Text style={PDFstyles.questionTitle}>{question.question}</Text>
                     {currentTrack?.notes?.filter((n) => n.question_id === question.question_id).map((note, i) => (
                        <View key={note.note_id} style={PDFstyles.note}>
                           <Text>{note.note}</Text>
                        </View>
                     ))}
                     {question?.answer_details?.links?.length > 0 && (
                        <>
                           <Text style={PDFstyles.reference}>References:</Text>
                           {question?.answer_details?.links?.map((link, index) => (
                              <Text>
                                 {index + 1}. <Link src={link}>{link}</Link>
                              </Text>
                           ))}
                        </>
                     )}
                  </View>
               )
            ))}
         </Page>
      </Document>
   );

   return (
      <div className="h-screen w-full flex flex-col justify-start">
         {loading && (
            <div className="w-screen h-screen flex items-center justify-center bg-white fixed top-0 right-0 z-50">
               <img src={load} alt='loading' />
            </div>
         )}
         <Snackbar
            open={copiedSnackbar}
            autoHideDuration={6000}
            onClose={() => setCopiedSnackbar(false)}
            message="Copied to clipboard"
         />
         <div className="text-2xl font-bold text-start px-4 pt-4 pb-0 flex items-center gap-3">
            <ArrowBack
               className="cursor-pointer"
               onClick={() => {
                  window.location.href = "/dashboard";
               }}
            />
            {editingTitle ? (
               <InputBase
                  value={tmpNewTitle}
                  onChange={(e) => {
                     setTmpNewTitle(e.target.value);
                  }}
                  onKeyDown={(e) => {
                     if (e.key === "Enter") {
                        editTrack(track?.track_id, tmpNewTitle);
                        setEditingTitle(false);
                     }
                  }}
                  autoFocus
                  className="w-full"
                  sx={{
                     fontSize: "1.5rem",
                     fontWeight: "bold",
                  }}
               />
            ) : (
               <>
                  {currentTrack?.title && (
                     <>
                        <span className="text-2xl">{currentTrack?.title}</span>
                        <EditOutlined
                           className="cursor-pointer"
                           onClick={() => {
                              setTmpNewTitle(currentTrack?.title);
                              setEditingTitle(true);
                           }}
                        />
                     </>
                  )}
               </>
            )}
         </div>
         <div className="self-stretch mr-4 relative flex justify-between">
            <div className="text-[#88848D] ml-14 mt-1">
               <AutoAwesome htmlColor="#FDC500" fontSize="small" className="mr-2" />
               Fetching answers from {track?.file_name ? track?.file_name : "the internet"}
            </div>
            {viewHiddenQuestions && (
               <div className="absolute top-[100%] right-0 bg-white w-96 border border-gray-900 rounded-md shadow-md p-4 z-10 my-3">
                  <Input
                     placeholder="Search for a question"
                     variant="filled"
                     className="w-full mb-4"
                     value={searchHiddenQuestions}
                     onChange={(e) => {
                        setSearchHiddenQuestions(e.target.value);
                     }}
                     startAdornment={
                        <InputAdornment position="start">
                           <SearchOutlined />
                        </InputAdornment>
                     }
                  />
                  <div className="flex flex-col gap-2">
                     {currentTrack?.questions
                        ?.filter((q) => q.hidden && q.question.toLowerCase().includes(searchHiddenQuestions.toLowerCase()))
                        .map((q, i) => {
                           return (
                              <div className="flex items-center justify-between gap-3 border border-gray-300 rounded-md p-2">
                                 <div className="text-[#88848d]">
                                    {q.question.split(searchHiddenQuestions).map((s, i) => {
                                       if (i === 0) {
                                          return s;
                                       }
                                       return (
                                          <>
                                             <span className="text-[#110A1C]">{searchHiddenQuestions}</span>
                                             {s}
                                          </>
                                       );
                                    })}
                                 </div>
                                 <span
                                    className="text-[#1877f2] cursor-pointer"
                                    onClick={() => {
                                       if (currentTrack?.questions?.filter((q) => q.hidden).length === 1) {
                                          setViewHiddenQuestions(false);
                                       }
                                       editQuestion(q.question_id, q.question, q.search_in_link, q.question_idx, q.created_from, false);

                                       if (q.question_id === bufferQuestion?.question_id) {
                                          setCurrentQuestion(bufferQuestion);
                                       }
                                    }}>
                                    Unhide
                                 </span>
                              </div>
                           );
                        })}
                     {currentTrack?.questions
                        ?.filter((q) => q.hidden && !q.question.toLowerCase().includes(searchHiddenQuestions.toLowerCase()))
                        .map((q, i) => {
                           return (
                              <div className="flex items-center justify-between gap-3 border border-gray-300 rounded-md p-2">
                                 <div className="text-[#88848d]">{q.question}</div>
                                 <span
                                    className="text-[#1877f2] cursor-pointer"
                                    onClick={() => {
                                       if (currentTrack?.questions?.filter((q) => q.hidden).length === 1) {
                                          setViewHiddenQuestions(false);
                                       }
                                       editQuestion(q.question_id, q.question, q.search_in_link, q.question_idx, q.created_from, false);
                                       if (q.question_id === bufferQuestion?.question_id) {
                                          setCurrentQuestion(bufferQuestion);
                                       }
                                    }}>
                                    Unhide
                                 </span>
                              </div>
                           );
                        })}
                  </div>
               </div>
            )}
            {currentTrack?.questions?.filter((q) => q.hidden).length > 0 && (
               <span className="text-sm font-light ml-auto mr-2">
                  one or more questions are hidden,{" "}
                  <span
                     className="text-[#1877f2] cursor-pointer"
                     onClick={() => {
                        setViewHiddenQuestions(!viewHiddenQuestions);
                     }}>
                     click to view
                  </span>
               </span>
            )}
            <Button
               variant="outlined"
               color="primary"
               onClick={() => {
                  setShowNotes("all");
               }}>
               <DescriptionOutlined
                  fontSize="small"
                  className="mr-2"
               />
               Show all notes
            </Button>
         </div>

         <div className="flex items-stretch gap-4 grow justify-start p-4 " style={{
            height: 'calc(100vh - 5.5rem)'
         }}>
            <div className="flex flex-col items-end gap-3 justify-start w-2/5">
               <div className='flex flex-col items-end gap-3 justify-start w-full overflow-y-auto scrollbar-hidden'>
                  {currentTrack?.questions
                     ?.filter((q) => !q.hidden)
                     .map((question, i) =>
                        question?.question_id === currentQuestion?.question_id ? (
                           <>
                              <OutlinedInput
                                 key={question.question_id}
                                 error={emptyTmpNewQuestion && editingQuestionId === question.question_id}
                                 value={editingQuestionId === question.question_id ? tmpNewQuestion : question.question}
                                 onChange={(e) => {
                                    editingQuestionId === question.question_id && setTmpNewQuestion(e.target.value);
                                 }}
                                 onKeyDown={async (e) => {
                                    if (e.key === "Enter" && editingQuestionId === question.question_id) {
                                       console.log("tmpNewQuestion", tmpNewQuestion);
                                       if (tmpNewQuestion.trim() !== "") {
                                          await editQuestion(question.question_id, tmpNewQuestion, question.search_in_link, question.question_idx, question.created_from, false);
                                          setEditingQuestionId(null);
                                          setTmpNewQuestion("");
                                          setEmptyTmpNewQuestion(false);
                                       } else {
                                          setEmptyTmpNewQuestion(true);
                                       }
                                    }
                                 }}
                                 onBlur={async () => {
                                    if (editingQuestionId === question.question_id) {
                                       setEditingQuestionId(null);
                                       setTmpNewQuestion("");
                                       setEmptyTmpNewQuestion(false);
                                    }
                                 }}
                                 size="small"
                                 fullWidth
                                 endAdornment={
                                    <InputAdornment
                                       position="end"
                                       className="cursor-pointer"
                                    >
                                       {(i === currentTrack?.questions?.filter((q) => !q.hidden)?.length - 1 && loadingAnswerId !== question?.question_id) && (
                                          <>
                                             <Tooltip title="Re-ask question" placement="top">
                                                <Replay className="mr-2" onClick={async () => {
                                                   await reAskQuestion(question)
                                                }} />
                                             </Tooltip>
                                             {editingQuestionId === question.question_id ? <CheckOutlined className="mr-2" onClick={async () => {
                                                console.log("tmpNewQuestion", tmpNewQuestion);
                                                if (tmpNewQuestion.trim() !== "") {
                                                   await editQuestion(question.question_id, tmpNewQuestion, question.search_in_link, question.question_idx, question.created_from, false);
                                                   setEditingQuestionId(null);
                                                   setTmpNewQuestion("");
                                                   setEmptyTmpNewQuestion(false);
                                                } else {
                                                   setEmptyTmpNewQuestion(true);
                                                }
                                             }} /> : <EditOutlined className="mr-2" onClick={() => {
                                                setTmpNewQuestion(question.question);
                                                setEditingQuestionId(question.question_id);
                                             }} />
                                             }
                                          </>
                                       )}
                                       {currentTrack?.questions?.filter((q) => !q.hidden).length > 1 && (
                                          <VisibilityOutlined
                                             className="mr-2"
                                             onClick={async () => {
                                                setBufferQuestion(currentQuestion);
                                                setCurrentQuestion(currentTrack?.questions?.filter((q) => !q.hidden && q.question_id !== currentQuestion?.question_id)[0]);
                                                await editQuestion(
                                                   currentQuestion?.question_id,
                                                   currentQuestion?.question,
                                                   currentQuestion?.search_in_link,
                                                   currentQuestion?.question_idx,
                                                   currentQuestion?.created_from,
                                                   true,
                                                );
                                             }}
                                          />
                                       )}
                                    </InputAdornment>
                                 }
                                 sx={{
                                    fontWeight: "bold",
                                    color: "#88848D",
                                    border: "2px solid #1877f2",
                                 }}
                              />
                              {currentQuestion?.answer_details?.suggested_questions?.length > 0 && (
                                 <div className="w-11/12 mb-2">
                                    <div className="flex items-center gap-2 justify-between">
                                       Suggested question based on the previous answer
                                       <span
                                          className={"text-sm cursor-pointer" + (showSuggestions ? " text-error" : " text-success")}
                                          onClick={() => {
                                             setShowSuggestions(!showSuggestions);
                                          }}>
                                          {showSuggestions ? "Close" : "Show"}
                                       </span>
                                    </div>
                                    {showSuggestions && (
                                       <>
                                          <div className="text-sm text-start">
                                             <span className="text-[#88848D]">
                                                Suggesting <span className="font-bold">{noOfSuggestedQuestions}</span> questions now,&nbsp;
                                             </span>
                                             <span
                                                className={updatingSuggestions ? "text-[#8BBBF8] cursor-not-allowed" : "text-[#1877f2] cursor-pointer"}
                                                onClick={() => {
                                                   setUpdatingSuggestions(true);
                                                }}>
                                                click to modify
                                             </span>
                                          </div>
                                          {updatingSuggestions && (
                                             <div className="text-[#1877f2] flex items-center justify-between gap-2">
                                                How many questions would you like to be suggested?
                                                <OutlinedInput
                                                   value={noOfSuggestedQuestions}
                                                   onChange={(e) => {
                                                      if (!isNaN(e.target.value)) {
                                                         setNoOfSuggestedQuestions(e.target.value);
                                                      }
                                                   }}
                                                   size="small"
                                                   className=""
                                                   sx={{
                                                      display: "inline-block",
                                                   }}
                                                   inputProps={{
                                                      style: {
                                                         textAlign: "center",
                                                         width: "3rem",
                                                      },
                                                   }}
                                                />
                                                <span
                                                   className="text-success text-sm cursor-pointer"
                                                   onClick={() => {
                                                      setUpdatingSuggestions(false);
                                                   }}>
                                                   Update
                                                </span>
                                             </div>
                                          )}
                                          {currentQuestion?.answer_details?.suggested_questions?.map((question, index) => (
                                             <>
                                                <div className="h-[1px] w-full bg-[#C3C2C6] my-3"></div>
                                                <div className="flex items-center justify-between text-start">
                                                   {index + 1}. {question}
                                                   <div className="text-[#1c1b1f] cursor-pointer">
                                                      <AddOutlined
                                                         fontSize="small"
                                                         onClick={async () => {
                                                            setCurrentQuestion(null);
                                                            const res = await createQuestion(question, "", currentTrack?.questions?.length || 0, "from_suggested_questions");
                                                            if (res) {
                                                               setCurrentQuestion(res);
                                                            }
                                                         }}
                                                      />
                                                   </div>
                                                </div>
                                             </>
                                          ))}
                                       </>
                                    )}
                                 </div>
                              )}
                           </>
                        ) : (
                           <>
                              <OutlinedInput
                                 key={question.question_id}
                                 error={emptyTmpNewQuestion && editingQuestionId === question.question_id}
                                 value={editingQuestionId === question.question_id ? tmpNewQuestion : question.question}
                                 onChange={(e) => {
                                    editingQuestionId === question.question_id && setTmpNewQuestion(e.target.value);
                                 }}
                                 onKeyDown={async (e) => {
                                    if (e.key === "Enter" && editingQuestionId === question.question_id) {
                                       console.log("tmpNewQuestion", tmpNewQuestion);
                                       if (tmpNewQuestion.trim() !== "") {
                                          await editQuestion(question.question_id, tmpNewQuestion, question.search_in_link, question.question_idx, question.created_from, false);
                                          setEditingQuestionId(null);
                                          setTmpNewQuestion("");
                                          setEmptyTmpNewQuestion(false);
                                       } else {
                                          setEmptyTmpNewQuestion(true);
                                       }
                                    }
                                 }}
                                 onBlur={async () => {
                                    if (editingQuestionId === question.question_id) {
                                       setEditingQuestionId(null);
                                       setTmpNewQuestion("");
                                       setEmptyTmpNewQuestion(false);
                                    }
                                 }}
                                 size="small"
                                 fullWidth
                                 endAdornment={
                                    <InputAdornment
                                       position="end"
                                       className="cursor-pointer"
                                    >
                                       {(i === currentTrack?.questions?.filter((q) => !q.hidden)?.length - 1 && loadingAnswerId !== question?.question_id) && (
                                          <>
                                             <Tooltip title="Re-ask question" placement="top">
                                                <Replay className="mr-2" onClick={async () => {
                                                   await reAskQuestion(question)
                                                }} />
                                             </Tooltip>
                                             {editingQuestionId === question.question_id ? <CheckOutlined className="mr-2" onClick={async () => {
                                                if (tmpNewQuestion.trim() !== "") {
                                                   await editQuestion(question.question_id, tmpNewQuestion, question.search_in_link, question.question_idx, question.created_from, false);
                                                   setEditingQuestionId(null);
                                                   setTmpNewQuestion("");
                                                   setEmptyTmpNewQuestion(false);
                                                } else {
                                                   setEmptyTmpNewQuestion(true);
                                                }
                                             }} /> : <EditOutlined className="mr-2" onClick={() => {
                                                setTmpNewQuestion(question.question);
                                                setEditingQuestionId(question.question_id);
                                             }} />
                                             }
                                          </>
                                       )}
                                       <VisibilityOutlined
                                          className="mr-2 z-1"
                                          onClick={() => {
                                             editQuestion(question.question_id, question.question, question.search_in_link, question.question_idx, question.created_from, true);
                                          }}
                                       />
                                    </InputAdornment>
                                 }
                                 sx={{
                                    fontWeight: "bold",
                                    color: "#88848D",
                                    cursor: "pointer",
                                 }}
                                 inputProps={{
                                    style: {
                                       cursor: "pointer",
                                    },
                                    onClick: (e) => {
                                       if (e.target.value !== "") {
                                          setCurrentQuestion(question);
                                       }
                                    },
                                 }}
                              />
                           </>
                        ),
                     )
                  }
               </div>
               <div className='mt-auto w-full'>
                  {loadingQuestion && <img src={load} alt='loading' className='self-center' />}
                  {addNewQuestion && <InputQuestion returnQuestion={returnQuestion} />}
                  {(!addNewQuestion && !loadingAnswerId) && (
                     <div className="mt-auto w-full justify-self-end mb-2 sticky">
                        <Button
                           variant="outlined"
                           color="secondary"
                           className="w-2/3 "
                           onClick={() => {
                              setAddNewQuestion(true);
                           }}>
                           <AddOutlined fontSize="small" />
                           Add question
                        </Button>
                     </div>
                  )}
               </div>
            </div>
            {currentTrack?.questions?.length > 0 ? (
               <>
                  <div className={"h-full border border-[#C3C2C6] rounded-lg flex flex-col items-stretch justify-start gap-2 p-2" + (showNotes ? " w-2/5" : " w-3/5")}>
                     <div className="flex items-center justify-end gap-2">
                        <div className="mr-auto font-bold text-[#88848D]">{currentQuestion?.question}</div>
                        {supported &&
                           (!speaking ? (
                              <VolumeUpOutlined
                                 fontSize="small"
                                 className="cursor-pointer"
                                 onClick={() => {
                                    speak({ text: currentQuestion?.answer_details?.answer || "" });
                                 }}
                              />
                           ) : (
                              <VolumeOffOutlined
                                 fontSize="small"
                                 className="cursor-pointer"
                                 onClick={() => {
                                    cancel();
                                 }}
                                 color="error"
                              />
                           ))}
                        <ContentCopyOutlined
                           fontSize="small"
                           onClick={() => {
                              navigator.clipboard.writeText(currentQuestion?.answer_details?.answer || "");
                              setCopiedSnackbar(true);
                           }}
                           className="cursor-pointer"
                        />
                     </div>
                     {(currentQuestion?.answer_details?.links?.length > 0 && loadingAnswerId !== currentQuestion?.question_id && loadingAnswerId !== 'new') && (
                        <div className="flex items-start gap-2 w-full items-center">
                           <div>Sources:</div>
                           <div className="flex flex-wrap items-center gap-1 w-9/12">
                              {currentQuestion?.answer_details?.links?.map(
                                 (link, index) =>
                                    (index < 3 || linksExpanded) && (
                                       <a
                                          href={link}
                                          target="_blank"
                                          rel="noreferrer noopener"
                                          key={index}
                                          className={"" + (showNotes ? " max-w-[49%]" : " max-w-[32%]")}>
                                          <Chip
                                             label={link}
                                             deleteIcon={<OpenInNew fontSize="small" />}
                                             onDelete={() => { }}
                                             sx={{
                                                backgroundColor: "#F5F5F6",
                                                color: "#88848D",
                                                cursor: "pointer",
                                             }}
                                          />
                                       </a>
                                    ),
                              )}
                           </div>
                           {currentQuestion?.answer_details?.links?.length > 3 && (
                              <div
                                 className="text-[#1877f2] cursor-pointer w-2/12 text-start text-sm mt-1"
                                 onClick={() => {
                                    setLinksExpanded(!linksExpanded);
                                 }}>
                                 show {linksExpanded ? "less" : "more"}
                              </div>
                           )}
                        </div>
                     )}
                     <div className={"flex flex-col overflow-y-auto scrollbar-hidden" + (currentQuestion?.answer_details?.answer ? currentQuestion?.answer_details?.answer === "No answer found" ? " items-center justify-center" : " text-center" : " items-center justify-center")}>
                        {(loadingAnswerId === currentQuestion?.question_id || loadingAnswerId === 'new') ? (
                           <Loader loading={loadingAnswerId === currentQuestion?.question_id || loadingAnswerId === 'new'} />
                        ) : (currentQuestion?.answer_details?.answer && currentQuestion?.answer_details?.answer !== "No answer found") ? (
                           <>
                              <ContextMenuTrigger id="answer-context" ref={c => contextTrigger = c}>
                                 <div className="overflow-y-auto text-start scrollbar-hidden" onMouseUp={(e) => {
                                    if (window?.getSelection()?.toString()?.length > 0) {
                                       toggleMenu(e);
                                    }
                                 }}>
                                    <LLMOutput data={currentQuestion?.answer_details?.answer} />
                                 </div>
                              </ContextMenuTrigger>
                              <ContextMenu id="answer-context">
                                 <MenuItem>
                                    <Button
                                       onClick={() => {
                                          if (window.getSelection().toString()) {
                                             createNote(window.getSelection().toString(), "text", currentQuestion?.question_id);
                                          }
                                          setEditingNote(-1);
                                          setShowNotes("question");
                                       }}
                                       // disabled={ (window.getSelection().toString().length === 0) }
                                       variant="contained">
                                       Add to note
                                    </Button>
                                 </MenuItem>
                              </ContextMenu>
                           </>
                        ) : (
                           <>
                              <img
                                 src={empty}
                                 alt="no answer"
                                 className=""
                              />
                              <div>Couldn't find an answer for this question</div>
                           </>
                        )}
                     </div>
                     <div className="mt-auto flex gap-2 items-center">
                        Was this answer useful?
                        {currentQuestion?.answer_details?.was_answer_useful ? (
                           <ThumbUp
                              color="secondary"
                              className="cursor-pointer"
                              fontSize="small"
                              onClick={async () => {
                                 await editAnswer(currentQuestion?.question_id, currentQuestion?.answer_details?.answer_id, null);
                              }}
                           />
                        ) : (
                           <ThumbUpOutlined
                              color="primary"
                              className="cursor-pointer"
                              fontSize="small"
                              onClick={async () => {
                                 await editAnswer(currentQuestion?.question_id, currentQuestion?.answer_details?.answer_id, true);
                              }}
                           />
                        )}
                        {currentQuestion?.answer_details?.was_answer_useful === false ? (
                           <ThumbDown
                              color="secondary"
                              className="cursor-pointer"
                              fontSize="small"
                              onClick={async () => {
                                 await editAnswer(currentQuestion?.question_id, currentQuestion?.answer_details?.answer_id, null);
                              }}
                           />
                        ) : (
                           <ThumbDownOutlined
                              color="primary"
                              className="cursor-pointer"
                              fontSize="small"
                              onClick={async () => {
                                 await editAnswer(currentQuestion?.question_id, currentQuestion?.answer_details?.answer_id, false);
                              }}
                           />
                        )}
                        <div className="ml-auto">
                           {!showNotes && (
                              <Button
                                 variant="outlined"
                                 color="yellow"
                                 size="small"
                                 onClick={() => {
                                    setShowNotes("question");
                                 }}>
                                 <DescriptionOutlined
                                    fontSize="small"
                                    className="mr-2"
                                 />
                                 Create note
                              </Button>
                           )}
                        </div>
                     </div>
                  </div>
                  {(showNotes === "question" || showNotes === "all") && (
                     <div className="h-full w-1/5 border border-[#C3C2C6] rounded-lg flex flex-col items-stretch justify-start gap-2 p-2">
                        <div className="flex items-center justify-end gap-2">
                           <div className="mr-auto font-bold text-[#1c1b1f]">Notes</div>
                           <Download
                              fontSize="small"
                              className="cursor-pointer"
                              onClick={(e) => {
                                 setDownloadOptionsAnchor(e.target)
                              }}
                           />
                           <Menu
                              id="basic-menu"
                              anchorEl={downloadOptionsAnchor}
                              open={Boolean(downloadOptionsAnchor)}
                              onClose={() => setDownloadOptionsAnchor(null)}
                              MenuListProps={{
                                 'aria-labelledby': 'basic-button',
                              }}
                           >
                              <MenuItem>
                                 <PDFDownloadLink document={<NotesPDF />} fileName={`${currentTrack?.title}.pdf`}>
                                    {({ blob, url, loading, error }) =>
                                       loading ? 'Loading document...' : <div className='px-3 py-1'>
                                          Raw export (.pdf)
                                       </div>

                                    }
                                 </PDFDownloadLink>
                              </MenuItem>
                              <MenuItem>
                                 <div className='text-[#8c8c8c] bg-[#F5F5F6] px-3 py-1'>
                                    Enriched (.pdf) (coming soon)
                                 </div>
                              </MenuItem>
                              <MenuItem>
                                 <div className='text-[#8c8c8c] bg-[#F5F5F6] px-3 py-1'>
                                    Enriched (.ppt) (coming soon)
                                 </div>
                              </MenuItem>
                           </Menu>
                           <CloseOutlined
                              fontSize="small"
                              onClick={() => {
                                 setShowNotes(undefined);
                              }}
                              className="cursor-pointer text-error"
                           />
                        </div>
                        {currentTrack?.notes?.length === 0 || !currentTrack?.notes ? (
                           <div className="grow flex flex-col items-center justify-center">
                              <img
                                 src={empty}
                                 alt="empty"
                                 className="w-1/2 mx-auto"
                              />
                              <div className="text-[#88848D] text-sm">Start creating your note...</div>
                           </div>
                        ) : (
                           <div className="flex grow flex-col items-start gap-5 overflow-y-auto scrollbar-hidden pt-2 text-start gap-2 h-full">
                              {currentTrack?.notes?.map((note, index) => {
                                 const date = new Date(note?.created_at);
                                 return (
                                    <div
                                       className={'w-full' + (note.question_id && ' cursor-pointer')}
                                       onClick={() => {
                                          if (note.question_id) {
                                             setCurrentQuestion(currentTrack?.questions?.find((q) => q.question_id === note.question_id));
                                          }
                                       }}
                                       key={note.note_id}>
                                       {note?.note_type === "text" && (
                                          <TextField
                                             value={editingNote === index ? tmpEditingNote : note.note}
                                             multiline
                                             fullWidth
                                             variant="outlined"
                                             label={
                                                note?.question_id && note?.question_id !== "undefined"
                                                   ? currentTrack?.questions?.find((question) => question.question_id === note.question_id)?.question
                                                   : ""
                                             }
                                             disabled={editingNote !== index}
                                             onChange={(e) => {
                                                setTmpEditingNote(e.target.value);
                                             }}
                                             size="small"
                                             InputLabelProps={{
                                                style: {
                                                   color: "#110A1C",
                                                },
                                             }}
                                             inputProps={{
                                                style: {
                                                   color: "#88848D",
                                                   cursor: note.question_id ? "pointer" : "text",
                                                },
                                             }}
                                          />
                                       )}
                                       {note.note_type === "voice" && (
                                          <>
                                             {note?.question_id && (
                                                <div className="text-[#88848D] text-xs border border-b-0 rounded-b-none border-[#C3C2C6] rounded-lg p-2 w-full">
                                                   {currentTrack?.questions?.find((question) => question.question_id === note.question_id)?.question}
                                                </div>
                                             )}
                                             <div className="border border-[#C3C2C6] rounded-lg w-full z-50">
                                                <AudioPlayer audioUrl={`${baseURL}/api/v1/notes/audio/${note.filename}`} />
                                             </div>
                                          </>
                                       )}
                                       <div className="w-full flex items-center justify-end gap-2 border border-t-0 rounded-t-none border-[#C3C2C6] rounded-lg p-2 w-full">
                                          <div className="text-[#88848D] text-xs mr-auto">Created on {date.toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" })}</div>
                                          <div
                                             className="text-error text-xs cursor-pointer mr-3 z-50"
                                             onClick={() => {
                                                confirm({
                                                   title: "Are you sure you want to delete this note?",
                                                   description: "This action cannot be undone.",
                                                })
                                                   .then(() => {
                                                      deleteNote(note.note_id);
                                                   })
                                                   .catch(() => { });
                                             }}>
                                             Delete
                                          </div>
                                          {note.note_type === "text" && (
                                             <div
                                                className="text-[#1877f2] text-xs cursor-pointer z-50"
                                                onClick={async () => {
                                                   if (editingNote === index) {
                                                      await editNote(note.note_id, tmpEditingNote, note.note_type, note.question_id);
                                                      setEditingNote(-1);
                                                   } else {
                                                      setTmpEditingNote(note.note);
                                                      setEditingNote(index);
                                                   }
                                                }}>
                                                {editingNote === index ? "Done" : "Edit"}
                                             </div>
                                          )}
                                       </div>
                                    </div>
                                 );
                              })}
                           </div>
                        )}
                        <div className="mt-auto">
                           {takingVoiceNote ? (
                              <AudioRecorder returnAudio={returnAudioNote} />
                           ) : (
                              <TextField
                                 variant="outlined"
                                 value={tmpNewNote}
                                 onChange={(e) => {
                                    setTmpNewNote(e.target.value);
                                 }}
                                 size="small"
                                 fullWidth
                                 placeholder="Start entering your note..."
                                 onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                       if (tmpNewNote.length > 0) {
                                          createNote(tmpNewNote, "text");
                                          setTmpNewNote("");
                                       }
                                    }
                                 }}
                                 InputProps={{
                                    endAdornment: (
                                       <InputAdornment position="end">
                                          {tmpNewNote.length > 0 ? (
                                             <Send
                                                color="primary"
                                                className="cursor-pointer"
                                                fontSize="small"
                                                onClick={() => {
                                                   if (tmpNewNote.length > 0) {
                                                      createNote(tmpNewNote, "text");
                                                      setTmpNewNote("");
                                                   }
                                                }}
                                             />
                                          ) : (
                                             <KeyboardVoice
                                                color="primary"
                                                className="cursor-pointer"
                                                fontSize="small"
                                                onClick={() => {
                                                   setTakingVoiceNote(true);
                                                   setTmpNewVoiceNote({
                                                      note: null,
                                                      note_type: "voice",
                                                      question_id: showNotes === "question" ? currentQuestion?.question_id : "",
                                                   });
                                                }}
                                             />
                                          )}
                                       </InputAdornment>
                                    ),
                                 }}
                              />
                           )}
                        </div>
                     </div>
                  )}
               </>
            ) : (
               <div className="grow border border-[#C3C2C6] rounded-lg flex justify-center items-center flex-col gap-4">
                  <img
                     src={empty}
                     alt="empty"
                     className=""
                  />
                  <div>Start creating new questions</div>
               </div>
            )}
         </div>
      </div>
   );
};

export default Answer;
