/**
 * eslint-disable jsx-a11y/anchor-is-valid
 *
 * @format
 */

import login from "./../../assets/images/login.png";
import { TextField, Checkbox, FormControlLabel, FormGroup, Button, FormControl, InputLabel, InputAdornment, IconButton, OutlinedInput, Snackbar, Alert, FormHelperText, Input } from "@mui/material";
import Logo from "../../components/Logo";
// import logo from '../../assets/images/android-chrome-512x512.png'
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createRequest } from "../../utils/crud";
import { useEffect } from "react";
import GoogleIcon from "../../assets/icons/GoogleIcon";
import { useGoogleLogin } from "@react-oauth/google";
import Loader from "../../components/Loader";
import axios from "axios";

const parseJwt = (token) => {
   if (!token) {
      return;
   }
   const base64Url = token.split(".")[1];
   const base64 = base64Url.replace("-", "+").replace("_", "/");
   return JSON.parse(window.atob(base64));
};

const Login = ({ page }) => {
   const location = useLocation();
   const [showPassword, setShowPassword] = useState(false);
   const [credentials, setCredentials] = useState({});
   const [snackbar, setSnackbar] = useState(false);
   const [snackbarDetails, setSnackbarDetails] = useState({});
   const [passwordError, setPasswordError] = useState(false);
   const [loading, setLoading] = useState(false);

   const navigate = useNavigate();

   const responseGoogle = async (response) => {
      // console.log('response', response);
      setLoading(true);

      // const res0 = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo?access_token=' + response.access_token)
      // const userObject = res0?.data
      // console.log('userObject', userObject)

      const res = await createRequest("api/v1/signup/google_sign_up", null, response?.access_token);
      // console.log(res?.data, "res.data");
      if (res?.status === 200 || res?.status === 201) {
         if (!res?.data?.onboarding) {
            res?.data?.access_token && localStorage.setItem("access_token", res?.data?.access_token);
            navigate("/onboarding");
            return;
         }
         if (res?.data?.access_token) {
            localStorage.setItem("access_token", res?.data?.access_token);
            navigate("/dashboard");
         }
      } else {
         setSnackbarDetails({ message: res?.data?.detail, severity: "error" });
         setSnackbar(true);
      }
      setLoading(false);
   };

   const handleGoogleLogin = useGoogleLogin({
      onSuccess: responseGoogle,
      onError: responseGoogle,
      flow: "implicit",
   });

   const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
   };

   const handleMouseDownPassword = (event) => {
      event.preventDefault();
   };

   const handleLogIn = async () => {
      setLoading(true);
      const res = await createRequest("api/v1/signup/login", credentials);
      setLoading(false);
      if ((res?.status === 200 || res?.status === 201) && res?.data) {
         localStorage.setItem("access_token", res?.data?.access_token);
         if (!res?.data?.onboarding) {
            navigate("/onboarding");
            return;
         }
         navigate("/dashboard");
      } else {
         setSnackbarDetails({ message: res?.data?.detail, severity: "error" });
         setSnackbar(true);
      }
   };
   const handleSignUp = async () => {
      if (credentials.password.length < 6) {
         setPasswordError(true);
         return;
      }
      setLoading(true);
      const res = await createRequest("api/v1/signup/sign_up", credentials);
      setLoading(false);
      if ((res?.status === 200 || res?.status === 201) && res?.data) {
         setSnackbarDetails({ message: "Check your email for verification", severity: "success" });
         setSnackbar(true);
      } else if (res?.status === 401) {
         setSnackbarDetails({ message: res?.data?.detail, severity: "error" });
         setSnackbar(true);
      } else {
         setSnackbarDetails({ message: res?.data?.detail, severity: "error" });
         setSnackbar(true);
      }
   };

   useEffect(() => {
      const tmpFunc = async () => {
         const access_token = new URL(window.location.href.replace("#", "?")).searchParams.get("access_token");
         if (access_token) {
            console.log("parsed", parseJwt(access_token));
            setCredentials({ ...credentials, email: parseJwt(access_token).email });
            setSnackbarDetails({ message: "Sign in to continue", severity: "info" });
            setSnackbar(true);
         }
         console.log(location.state?.error, "location.state?.error");
         if (location.state?.error) {
            setSnackbarDetails({ message: location?.state?.error, severity: "error" });
            setSnackbar(true);
         }
      };
      tmpFunc();
   }, []);

   return (
      <div className="h-screen flex justify-center items-center ">
         <Loader loading={loading} />
         <Snackbar
            open={snackbar}
            autoHideDuration={6000}
            onClose={() => {
               setSnackbar(false);
               setSnackbarDetails({});
            }}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <Alert
               onClose={() => {
                  setSnackbar(false);
                  setSnackbarDetails({});
               }}
               severity={snackbarDetails.severity}
               sx={{ width: "100%" }}>
               {snackbarDetails.message}
            </Alert>
         </Snackbar>
         <div className=" w-full lg:w-1/2 bg-[#FFC5DD] h-full flex justify-center items-center">
            <img
               src={login}
               alt="Logo"
               className="w-full"
            />
         </div>
         <form className="w-full lg:w-1/2 h-full px-24 flex flex-col justify-center items-center gap-6 lg:relative">
            <div className="absolute top-4 left-4 flex justify-center items-center">
               <Logo className="text-2xl" />
            </div>
            <div className="w-[65%] flex flex-col items-center justify-center gap-2">
               <div className="w-full gap-5 flex flex-col justify-between items-center my-4">
                  <div className="flex flex-col items-start justify-center gap-3 w-[100%]">
                     <span className="text-3xl font-bold">{page === "login" ? "Welcome to typeLess" : "Sign Up"}</span>
                     <span className="text-[#ACACAD]">Welcome to typeLess! Please enter your details</span>
                  </div>

                  <Button
                     variant="outlined"
                     className="w-full h-12 flex justify-center items-center gap-2 "
                     onClick={handleGoogleLogin}>
                     <span>
                        <GoogleIcon />
                     </span>
                     <span>{page === "login" ? "Sign in with Google" : "Sign up with Google"}</span>
                  </Button>
                  <div className="flex justify-between items-center gap-1 w-full">
                     <div className="w-1/2">
                        <hr />
                     </div>
                     <div className="text-gray-400">or</div>
                     <div className="w-1/2">
                        <hr />
                     </div>
                  </div>
               </div>
               <TextField
                  id="email-input"
                  label="Email"
                  variant="standard"
                  className="w-full"
                  size="small"
                  value={credentials.email}
                  onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
               />
               <div className="w-full mt-5 text-end">
                  <FormControl
                     className="w-full p-0"
                     variant="standard">
                     <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                     <Input
                        id="standard-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                           <InputAdornment position="end">
                              <IconButton
                                 aria-label="toggle password visibility"
                                 onClick={handleClickShowPassword}
                                 onMouseDown={handleMouseDownPassword}>
                                 {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                           </InputAdornment>
                        }
                        value={credentials.password}
                        onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                     />
                     <FormHelperText
                        id="component-error-text"
                        error={passwordError}>
                        {page === "register" && "Password must be at least 6 characters long"}
                     </FormHelperText>
                  </FormControl>
               </div>
               <FormGroup
                  className="w-full"
                  sx={{
                     flexDirection: "row",
                     justifyContent: "space-between",
                  }}>
                  <FormControlLabel
                     control={
                        <Checkbox
                           value={credentials.remember}
                           onChange={(e) => setCredentials({ ...credentials, remember: e.target.checked })}
                        />
                     }
                     sx={{
                        width: "fit-content",
                     }}
                     label="Remember me"
                  />
                  {page === "login" && (
                     <span
                        className="text-[#000] underline mt-2 cursor-pointer"
                        onClick={(e) => {
                           e.preventDefault();
                           navigate("/forgot-password");
                        }}>
                        Forgot Password?
                     </span>
                  )}
               </FormGroup>
               <Button
                  variant="contained"
                  className="w-full h-12 flex justify-center items-center gap-2"
                  sx={{ marginTop: "2rem" }}
                  disabled={!credentials.email || !credentials.password}
                  onClick={page === "login" ? handleLogIn : handleSignUp}>
                  {page === "login" ? "Sign In" : "Create Account"}
               </Button>
               {page === "register" && (
                  <div className="text-xs">
                     *by continuing you agree to our
                     <span className="text-[#000] underline cursor-pointer">
                        {" "}
                        <a
                           target="_blank"
                           href="/tandc.html">
                           terms of service
                        </a>
                     </span>
                     ,
                     <span className="text-[#000] underline cursor-pointer">
                        {" "}
                        <a href="https://portal.termshub.io/typeless.io/privacy_policy/">privacy policy</a>
                     </span>{" "}
                     and
                     <span className="text-[#000] underline cursor-pointer">
                        {" "}
                        <a href="https://portal.termshub.io/typeless.io/cookie_policy/">cookies policy</a>
                     </span>
                     .
                  </div>
               )}

               <div className="mt-5 flex gap-2 items-center">
                  <span className="text-[#ACACAD] font-[500] ">{page === "register" ? "Already have account ? " : "Don't have account ? "}</span>
                  <span
                     className="cursor-pointer text-[#000] font-[500] "
                     onClick={() => navigate(page === "register" ? "/" : "/register")}>
                     {page === "register" ? "Sign in" : " Sign up for free"}
                  </span>
               </div>
            </div>
         </form>
      </div>
   );
};

export default Login;
