import load from './../../assets/images/animation_llw26k69_small.gif'
import { BlockOutlined, CalendarMonthOutlined, SwitchRight, CurrencyRupee, DeleteOutlined, GroupOutlined, List, MailOutlineOutlined, MoreVert, Search, Close, InfoOutlined, LockOutlined, LockOpenOutlined, MoreHoriz, RemoveModeratorOutlined, AddModeratorOutlined, LocalLibraryOutlined } from "@mui/icons-material";
import { Avatar, Button, Chip, InputBase, Paper, Checkbox, MenuItem, Modal, TextField, Select, Autocomplete, createFilterOptions, Dialog, Tooltip } from "@mui/material";
import Sidebar from "../../components/Sidebar"
import { useEffect, useRef, useState } from "react";
import { createRequest, deleteRequest, readRequest } from "../../utils/crud";
import empty from "./../../assets/images/empty.png";
import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";

const Admin = () => {
    const navigate = useNavigate();
    const confirm = useConfirm();
    const filter = createFilterOptions();

    const [accountDeets, setAccountDeets] = useState({ profile_name: "John Doe" });
    const [allTags, setAllTags] = useState([])
    // const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [lastAdminRemoval, setLastAdminRemoval] = useState(false)

    const colors = [
        '#BB6BD9',
        '#2D9CDB',
        '#F2994A',
        '#219653',
        '#2F80ED',
        '#9B51E0',
        '#3FC6F2',
        '#F2994A'
    ]
    const tagColors = [
        '#FDFFB6',
        '#BDB2FF',
        '#CAFFBF',
        '#FFD6A5',
        '#9BF6FF',
        '#FFAFCC'
    ]
    const blockUnits = [
        "hours",
        "days",
        "weeks",
        "months"
    ]
    const [selected, setSelected] = useState([])
    const [sorting, setSorting] = useState({ type: 'profile_name', order: 'asc' })
    const [blockModal, setBlockModal] = useState(false)
    const [blockUser, setBlockUser] = useState({})
    const [blockDuration, setBlockDuration] = useState({ duration: 2, unit: 'hours'})
    const [searchQuery, setSearchQuery] = useState('')

    const fetchData = async () => {
        setLoading(true)
        const res1 = await readRequest('api/v1/users')
        console.log(res1?.data, 'res1?.data')
        if (res1?.data) {
            setAccountDeets({
                profile_name: res1.data?.profile_name ?? "",
             });
        }
        const res = await readRequest('api/v1/admin/get_all_users')
        console.log(res?.data, 'res?.data')
        if (res?.status === 200) {
            setData(res?.data?.users)
        } else if (res?.status === 401) {
            navigate('/')
        } else if (res?.status === 403) {
            localStorage.setItem('activeSidebar', 0)
            navigate('/dashboard')
        }
        const res2 = await readRequest('api/v1/admin/get_all_tags')
        console.log(res2?.data, 'res2?.data')
        if (res2?.status === 200) {
            setAllTags(res2?.data?.tags)
        }
        setLoading(false)
    }
    const handleBlockUser = async () => {
        setLoading(true)
        let payload = { user_id: blockUser?.user_id } 
        blockUnits.forEach((unit) => {
            if (unit === blockDuration?.unit) {
                payload = { ...payload, [unit]: blockDuration?.duration }
            } else {
                payload = { ...payload, [unit]: 0 }
            }
        })
        const res = await createRequest('api/v1/admin/block_user', payload)
        console.log(res?.data, 'res?.data')
        if (res?.status === 200) {
            fetchData()
        }
        setLoading(false)
    }
    const handleUnblockUser = async (user) => {
        confirm({ description: `Are you sure you want to unblock ${user?.profile_name ?? 'this user'}?` })
        .then(async () => {
            setLoading(true)
            const res = await createRequest('api/v1/admin/unblock_user', { user_id: user?.user_id })
            console.log(res?.data, 'res?.data')
            if (res?.status === 200) {
                fetchData()
            }
            setLoading(false)
        })
        .catch(() => {})
    }
    const handleDeleteUser = async (user) => {
        confirm({ description: `Are you sure you want to delete ${user?.profile_name ?? 'this user'}?` })
        .then(async () => {
            setLoading(true)
            const res = await createRequest('api/v1/admin/delete_user', { user_id: user?.user_id })
            console.log(res?.data, 'res?.data')
            if (res?.status === 200) {
                fetchData()
            }
            setLoading(false)
        })
        .catch(() => {})
    }

    const handleDeleteTagFromUser = async (user, tag) => {
        setLoading(true)
        const res = await createRequest('api/v1/admin/delete_tag_user', { user_id: user.user_id, tag_id: tag.tag_id })
        console.log(res?.data, 'res?.data')
        if (res?.status === 200) {
            setData(data.map((item) => {
                if (item.user_id === user.user_id) {
                    console.log(item.result, 'item.result', tag, 'tag')
                    return { ...item, result: item.result.filter((t) => t.title !== tag.title) }
                } else {
                    return item
                }
            }))
        }
        setLoading(false)
    }
    const handleAddTag = async (user, tags) => {
        setLoading(true)
        console.log(tags, 'tags')
        const newT = tags.filter((tag) => user.result.filter((t) => t.title === tag.title).length === 0)
        console.log(newT, 'newT')
        if (newT.length > 0) {
            let getAllTagsAfterAdd = false
            let payload = { user_id: user.user_id }
            if (newT[0].inputValue) {
                payload = { ...payload, title: newT[0].inputValue, color: tagColors[allTags.length % tagColors.length], tag: 'new' }
                getAllTagsAfterAdd = true
            } else {
                payload = { ...payload, tag_id: newT[0].tag_id, tag: 'old' }
            }
            const res = await createRequest('api/v1/admin/add_tag', payload)
            console.log(res?.data, 'res?.data')
            if (res?.status === 200) {
                setData(data.map((item) => {
                    if (item.user_id === user.user_id) {
                        return {
                            ...item, 
                            result: tags.map((tag) => {
                                if (tag.title === newT[0].title || tag.inputValue === newT[0].title) {
                                    return res?.data
                                }
                                return tag
                            })
                        }
                    } else {
                        return item
                    }
                }))
                if (getAllTagsAfterAdd) {
                    setAllTags([...allTags, res?.data])
                }
            }
        } 
        setLoading(false)
    }
    const handleDeleteTag = async (tag) => {
        confirm({ description: `Are you sure you want to delete ${tag?.title ?? 'this tag'}?` })
        .then(async () => {
            setLoading(true)
            const res = await deleteRequest('api/v1/admin/delete_tag/' + tag.tag_id)
            console.log(res?.data, 'res?.data')
            if (res?.status === 200) {
                setAllTags(allTags.filter((item) => item.tag_id !== tag.tag_id))
                const res2 = await readRequest('api/v1/admin/get_all_users')
                console.log(res2?.data, 'res2?.data')
                if (res2?.status === 200) {
                    setData(res2?.data?.users)
                }
            }
            setLoading(false)
        })
    }

    const handleAddAdmin = async (user) => {
        await confirm({ description: `Are you sure you want to make ${user?.profile_name ?? 'this user'} an admin?` })
        setLoading(true)
        const res = await createRequest('api/v1/admin/make_admin', { user_id: user.user_id })
        console.log(res?.data, 'res?.data')
        if (res?.status === 200) {
            fetchData()
        }
        setLoading(false)
    }
    const handleRemoveAdmin = async (user) => {
        if (data.reduce((acc, item) => item.admin ? acc + 1 : acc, 0) === 1) {
            setLastAdminRemoval(true)
            return
        }
        await confirm({ description: `Are you sure you want to remove ${user?.profile_name ?? 'this user'} as an admin?` })
        setLoading(true)
        const res = await createRequest('api/v1/admin/remove_admin', { user_id: user.user_id })
        console.log(res?.data, 'res?.data')
        if (res?.status === 200) {
            fetchData()
        }
        setLoading(false)
    }


    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="flex">
            <Dialog
                open={lastAdminRemoval}
                onClose={() => setLastAdminRemoval(false)}
            >
                <div className="flex items-center justify-between p-4 h-48 flex-col text-center">
                <div></div>
                Can not remove admin. <br />There has to be atleast one admin at a time.
                <Button variant="contained" className="ml-4" onClick={() => setLastAdminRemoval(false)}>
                    Go Back
                </Button>
                </div>
            </Dialog>

            <Modal
                open={blockModal}
                onClose={() => setBlockModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex justify-center items-center"
            >
                <div className="flex justify-start items-stretch flex-col bg-white rounded-lg w-[30rem]">
                    <div className="flex justify-between items-center p-4 border-b-2">
                        <div className="font-bold text-xl text-error">
                            Block User
                        </div>
                        <Button variant="outlined" size="small" onClick={() => setBlockModal(false)} sx={{ minWidth: '0px', aspectRatio: '1/1' }}>
                            <Close />
                        </Button>
                    </div>
                    <div className="p-4 flex flex-col items-center">
                        <div>
                            <div className="flex items-center gap-2 mb-3">
                                <div>Block this user for</div>
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    sx={{ minWidth: '0', maxWidth: '5rem' }}
                                    value={blockDuration?.duration}
                                    onChange={(e) => setBlockDuration({ ...blockDuration, duration: e.target.value })}
                                />
                                <Select
                                    variant="outlined"
                                    size="small"
                                    sx={{ minWidth: '0', maxWidth: '7rem' }}
                                    value={blockDuration?.unit}
                                    onChange={(e) => setBlockDuration({ ...blockDuration, unit: e.target.value })}
                                >
                                    {blockUnits.map((unit, index) => (
                                        <MenuItem key={index} value={unit} className="capitalize">{unit}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <div className="text-sm text-[#88848D] flex items-center gap-2">
                                <InfoOutlined htmlColor="#88848D" />
                                You can unblock the user anytime.
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end items-center p-4 gap-2">
                        <Button variant="outlined"  onClick={() => setBlockModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="error" onClick={() => {setBlockModal(false); handleBlockUser()}}>
                            Block User
                        </Button>
                    </div>
                </div>
            </Modal>
            <Sidebar menu={[
                { name: 'User Details', icon: <GroupOutlined />, link: '/admin'},
                { name: 'Dashboard', icon: <LocalLibraryOutlined />, link: '/dashboard'},
                // { name: 'Pricing', icon: <CurrencyRupee />, link: 'admin/pricing'},
            ]} />
            {loading && <div className="w-screen h-screen flex items-center justify-center bg-white absolute top-0 right-0 z-50">
               <img src={load} alt='loading' />
            </div>
            }
            <div className="bg-[#F7F9FB] grow p-4 h-screen overflow-y-scroll flex flex-col gap-2">
                <div className="font-bold text-2xl text-start mt-4">
                    User Details
                </div>
                <Paper className="px-2 py-0 mt-3 flex items-center border-2" sx={{ boxShadow: 'none' }}>
                    <Search htmlColor="#88848D" />
                    <InputBase 
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search users"
                        type="search" 
                        size="small"
                        className="grow"
                        inputProps={{
                            sx: {
                                padding: '.5rem '
                            }
                        }}
                    /> 
                </Paper>
                <Paper className="grow px-2 py-0 flex items-start border-2 relative" sx={{ boxShadow: 'none' }}> 
                    <table className="w-full">
                        <thead>
                            <tr className="border-b-2 text-[#88848D]">
                                <th className="p-2 font-normal"><Checkbox color="primary" checked={selected?.length === data?.length} onChange={(e) => {  if (e.target.checked) {setSelected(data?.map((item) => item.user_id))} else setSelected([]) }} /></th>
                                <th className="p-2 font-normal text-start">
                                    <GroupOutlined htmlColor="#88848D" fontSize="small" /> User Name
                                    <SwitchRight fontSize="small" color='primary' className={'cursor-pointer ml-2' + (sorting.type === 'profile_name' ? (sorting.order === 'asc' ? ' rotate-[270deg]' : ' rotate-90') : '')} onClick={() => { 
                                        if (sorting.type === 'profile_name') { sorting.order === 'asc' ? setSorting({ type: 'profile_name', order: 'desc' }) : setSorting({ type: '', order: '' }) }
                                        else { setSorting({ type: 'profile_name', order: 'asc' }) } }} 
                                    />
                                </th>
                                <th className="p-2 font-normal text-start">
                                    <MailOutlineOutlined htmlColor="#88848D" fontSize="small" /> Email
                                    <SwitchRight fontSize="small" color='primary' className={'cursor-pointer ml-2' + (sorting.type === 'email' ? (sorting.order === 'asc' ? ' rotate-[270deg]' : ' rotate-90') : '')} onClick={() => {
                                        if (sorting.type === 'email') { sorting.order === 'asc' ? setSorting({ type: 'email', order: 'desc' }) : setSorting({ type: '', order: '' }) }
                                        else { setSorting({ type: 'email', order: 'asc' }) } }} 
                                />
                                </th>
                                <th className="p-2 font-normal text-start">
                                    <CalendarMonthOutlined htmlColor="#88848D" fontSize="small" /> Joined
                                    <SwitchRight fontSize="small" color='primary' className={'cursor-pointer ml-2' + (sorting.type === 'created_at' ? (sorting.order === 'asc' ? ' rotate-[270deg]' : ' rotate-90') : '')} onClick={() => {
                                        if (sorting.type === 'created_at') { sorting.order === 'asc' ? setSorting({ type: 'created_at', order: 'desc' }) : setSorting({ type: '', order: '' }) }
                                        else { setSorting({ type: 'created_at', order: 'asc' }) } }}
                                    />
                                </th>
                                <th className="p-2 font-normal text-start"><List htmlColor="#88848D" fontSize="small" /> Tags</th>
                                <th className="p-2 font-normal text-start"><MoreVert htmlColor="#88848D" fontSize="small" />Actions</th>
                            </tr>
                        </thead>
                        <tbody className="relative">
                            {data?.sort((a, b) => {
                                if (sorting.order === 'asc') {
                                    let x = a[sorting?.type]?.toLowerCase()
                                    let y = b[sorting?.type]?.toLowerCase()
                                    if (x < y) { return -1 }
                                    if (x > y) { return 1 }
                                    return 0
                                } else {
                                    let x = a[sorting?.type]?.toLowerCase()
                                    let y = b[sorting?.type]?.toLowerCase()
                                    if (x < y) { return 1 }
                                    if (x > y) { return -1 }
                                    return 0
                                }
                            }).filter((user) => {
                                if (searchQuery === '') { return true }
                                else {
                                    let x = user?.profile_name?.toLowerCase()
                                    let y = user?.email?.toLowerCase()
                                    let z = user?.result?.map((item) => item.title)?.join(' ')?.toLowerCase()
                                    let a = searchQuery?.toLowerCase()
                                    if (x?.includes(a) || y?.includes(a) || z?.includes(a)) { return true }
                                    else { return false }
                                }
                            }).map((user, index) => (
                                <tr key={index} className="py-2">
                                    <td className="p-2"><Checkbox color="primary" checked={selected.includes(user.user_id)} onChange={(e) => { if (e.target.checked) {setSelected([...selected, user.user_id])} else setSelected(selected.filter((item) => item !== user.user_id)) }} /></td>
                                    <td className="p-2 flex items-center">
                                        <Avatar sx={{ bgcolor: colors[index % colors.length] }}>{user.profile_name && user.profile_name[0]}</Avatar>
                                        <span className="ml-2">{user.profile_name}</span>
                                    </td>
                                    <td className="p-2 text-start">{user.email}</td>
                                    <td className="p-2 text-start">{new Date(user.created_at).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</td>
                                    <td className="p-2 text-start">
                                        <Autocomplete
                                            multiple
                                            limitTags={1}
                                            selectOnFocus
                                            clearOnBlur
                                            handleHomeEndKeys
                                            variant="contained"
                                            size="small"
                                            filterSelectedOptions
                                            disableClearable
                                            value={user.result}
                                            disableCloseOnSelect
                                            openOnFocus
                                            forcePopupIcon
                                            sx={{
                                                minWidth: '7rem',
                                                '& .Mui-focused': {
                                                    border: 'none',
                                                    outline: 'none',
                                                    backgroundColor: '#F7F9FB'
                                                },
                                                '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                                                    minWidth: '0px',
                                                },
                                            }}
                                            onChange={(event, newValue) => { handleAddTag(user, newValue) }}
                                            filterOptions={(options, params) => {
                                                const filtered = filter(options, params);
                                        
                                                const { inputValue } = params;
                                                // Suggest the creation of a new value
                                                const isExisting = options.some((option) => inputValue === option);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered.push({
                                                        inputValue,
                                                        title: `Add "${inputValue}"`,
                                                        color: tagColors[allTags.length % tagColors.length]
                                                    });
                                                }
                                        
                                                return filtered;
                                            }}
                                            options={allTags}
                                            getOptionLabel={(option) => {
                                                if (option.title) {
                                                    return option.title;
                                                }
                                                return option;
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    size="small"
                                                    placeholder={user.result?.length > 0 ? "" : 'Click to add tags' }
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        sx: {
                                                            '& .Mui-focused': {
                                                                border: 'none',
                                                                outline: 'none',
                                                                backgroundColor: '#F7F9FB'
                                                            }
                                                        },
                                                        endAdornment: <></>,
                                                    }}
                                                />
                                            )}
                                            renderOption={(props, option) => {
                                                return (
                                                    <div {...props} className="flex items-center justify-between pb-1 px-3" key={option?.title || option}>
                                                        <Chip variant="outlined" size="small" label={option?.title || option} className="capitalize" style={{ backgroundColor: option.color, border: 'none', borderRadius: '0.25rem', display: 'inline-block' }} />
                                                        <DeleteOutlined fontSize="small" onClick={(e) => { e.stopPropagation(); handleDeleteTag(option) }} color="error" className="cursor-pointer" />
                                                    </div>
                                                );
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="outlined" size="small" label={option?.title || option} className="capitalize" style={{ backgroundColor: option.color, border: 'none', borderRadius: '0.25rem', marginRight: '2px', display: 'inline-block' }} deleteIcon={<Close />} onDelete={() => handleDeleteTagFromUser(user, option)} key={index} />
                                                ))
                                            }
                                            isOptionEqualToValue={(option, value) => {
                                                console.log(option, value, 'option, value')
                                                return option?.title === value?.title;
                                            }}
                                            ListboxProps={{
                                                style: {
                                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
                                                    border: '1px solid #D6D9DE',
                                                    borderRadius: '0.25rem',
                                                    width: '100%',
                                                }
                                            }}
                                            getLimitTagsText={(more) => `+${more} more`}
                                        />
                                    </td>
                                    <td className="p-2 flex items-center gap-2">
                                        <Tooltip title="Delete User" placement="top"><Button variant="outlined" size="small" className="mr-2 " color="error" sx={{ minWidth: 0, aspectRatio: '1/1' }} aria-label="delete" onClick={() => {handleDeleteUser(user);}}><DeleteOutlined fontSize="small" /></Button></Tooltip>
                                        {!user?.blocked ? 
                                            <Tooltip title="Block User" placement="top"><Button variant="outlined" size="small" className="mr-2 " color="success" sx={{ minWidth: 0, aspectRatio: '1/1' }} onClick={()=> {setBlockModal(true); setBlockUser(user)}} aria-label="block"><LockOutlined fontSize="small" /></Button></Tooltip>
                                            :
                                            <Tooltip title="Unblock User" placement="top"><Button variant="outlined" size="small" className="mr-2 " color="error" sx={{ minWidth: 0, aspectRatio: '1/1' }} aria-label="unblock" onClick={()=> {handleUnblockUser(user)}}><LockOpenOutlined fontSize="small" /></Button></Tooltip>
                                        }
                                        {user?.admin ? 
                                            <Tooltip title="Remove Admin" placement="top"><Button variant="outlined" size="small" className="mr-2 " color="success" sx={{ minWidth: 0, aspectRatio: '1/1' }} onClick={()=> {handleRemoveAdmin(user)}} aria-label="remove admin"><RemoveModeratorOutlined fontSize="small" /></Button></Tooltip>
                                            :
                                            <Tooltip title="Make Admin" placement="top"><Button variant="outlined" size="small" className="mr-2 " color="error" sx={{ minWidth: 0, aspectRatio: '1/1' }} aria-label="make admin" onClick={()=> {handleAddAdmin(user)}}><AddModeratorOutlined fontSize="small" /></Button></Tooltip>
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {data?.filter((user) => {
                        if (searchQuery === '') { return true }
                        else {
                            let x = user?.profile_name?.toLowerCase()
                            let y = user?.email?.toLowerCase()
                            let z = user?.result?.map((item) => item.title)?.join(' ')?.toLowerCase()
                            let a = searchQuery?.toLowerCase()
                            if (x?.includes(a) || y?.includes(a) || z?.includes(a)) { return true }
                            else { return false }
                        }
                    })?.length === 0 && 
                        <div className="p-2 text-center flex flex-col justify-center items-center w-full absolute top-[30%]" >
                            <img src={empty} alt="Empty" className="w-[13rem]" />
                            <div>Nothing to see here</div>
                        </div>
                    }

                </Paper>
            </div>
        </div>
    )
}

export default Admin