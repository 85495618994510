import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { readRequest } from "../../utils/crud"

const CancelledPayment = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [timer, setTimer] = useState(5)

    const logCheck = async () => {
        const res = await readRequest(`api/v1/payments/checkout/cancelled/${id}`)
        console.log(res, 'res')
        setTimeout(() => {
            localStorage.setItem('activeSidebar', 1)
            navigate('/credits', { state: { success: false } })
        }, 5000);
        setInterval(() => {
            setTimer(timer => timer - 1)
        }, 1000);
    }
    useEffect(() => {
        console.log(id, 'id')
        logCheck()
    }, [])
    return (
        <div>
            <h1>Payment Cancelled yay</h1>
            <h3>Payment ID: {id}</h3>
            <h4>Redirecting to dashboard... in {timer} seconds</h4>
        </div>
    )
}

export default CancelledPayment