import { SettingsOutlined } from "@mui/icons-material";
import Sidebar from "../../components/Sidebar";
import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { createRequest, readRequest } from "../../utils/crud";

const Settings = () => {
    const [email, setEmail] = useState(false)

    const getInitially = async () => {
        const res = await readRequest("api/v1/users/");
        if (res?.status === 200 || res?.status === 201) {
            setEmail(res?.data?.email_preferences)
        }
    }
    const updateEmailPref = async (e) => {
        const res = await createRequest("api/v1/users/email_preferences", { email_preferences: e.target.checked })
        if (res?.status === 200 || res?.status === 201) {
            setEmail(!email)
        }
    }
    useEffect(() => {
        getInitially()
    }, [])

    return (
        <div className="flex relative">
            <Sidebar menu={[
                {
                    name: "Settings",
                    link: "/settings",
                    icon: <SettingsOutlined />
                }
            ]} />
            <div className="bg-[#F7F9FB] grow p-4 h-screen overflow-y-scroll">
                <div className="font-bold text-2xl text-start mt-4">Settings</div>
                <div className="flex flex-col mt-8 self-start text-start lg:w-7/12">
                    <div className="w-full flex items-center">
                        Do you want to receive marketing emails?
                        <Switch
                            className="ml-auto"
                            color="secondary"
                            checked={email}
                            onChange={updateEmailPref}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings;