import login from './../../assets/images/login.png'
import Logo from '../../components/Logo'
import { Button, TextField, Snackbar, Alert } from '@mui/material'
import { useState } from 'react'
import { createRequest } from '../../utils/crud'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [credentials, setCredentials] = useState({})
    const [snackbar, setSnackbar] = useState(false)
    const [snackbarDetails, setSnackbarDetails] = useState({})

    const handleForgotPassword = async () => {
        const res = await createRequest('api/v1/signup/forgot_password', credentials);
        if ((res?.status === 200 || res?.status === 201) && res?.data) {
            setSnackbarDetails({ message: 'Check your email for verification', severity: 'success' });
            setSnackbar(true);
        } else if (res?.status === 500) {
            setSnackbarDetails({ message: 'This email is not registered', severity: 'error' });
            setSnackbar(true);
        } else {
            setSnackbarDetails({ message: 'Something went wrong', severity: 'error' });
            setSnackbar(true);
        }
    }


    return (
        <div className="h-screen flex justify-center items-center flex-wrap">
            <Snackbar
                open={snackbar}
                autoHideDuration={6000}
                onClose={() => {
                    setSnackbar(false);
                    setSnackbarDetails({});
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => {
                    setSnackbar(false);
                    setSnackbarDetails({});
                }} severity={snackbarDetails.severity} sx={{ width: '100%' }}>
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>

            <div className=" w-full lg:w-1/2 bg-[#FFC5DD] h-full flex justify-center items-center">
                <img src={login} alt="Logo" className="w-full" />
            </div>
            <div className="w-full lg:w-1/2 h-full px-24 flex flex-col justify-start items-center gap-6 lg:relative pt-40">
                <div className="absolute top-4 left-4 flex justify-center items-center">
                    <Logo />
                </div>
                <div className='text-3xl font-bold w-full text-start'>
                    Forgot Password
                </div>
                <TextField
                    id="email-input"
                    label="Email"
                    variant="outlined"
                    className='w-full'
                    sx={{ marginTop: '2rem' }}
                    size='small'
                    value={credentials.email}
                    onChange={(e) => setCredentials({...credentials, email: e.target.value})}
                />
                <Button variant="contained" className='w-full' sx={{ marginTop: '2rem'}} disabled={!credentials.email} onClick={handleForgotPassword}>
                    Get a link
                </Button>
                <div className='text-indigo-500 underline mt-2 cursor-pointer' onClick={(e) => {
                    e.preventDefault();
                    navigate('/');
                }}>
                    <ArrowBack className='mr-2' fontSize='small'/>
                    Go back
                </div>

            </div>
        </div>
    )
}

export default ForgotPassword