import { ButtonGroup, Button } from "@mui/material"

const PageIndicator = ({ currentPage, totalPages }) => {
    return (
        <ButtonGroup
            variant="contained"
            aria-label="contained primary button group"
            size="small"
            use='indicator'
            className="mt-2"
        >
            {Array.from({ length: totalPages }, (_, i) => (
                <Button
                    key={i}
                    disabled={i !== currentPage}
                ></Button>
            ))}
        </ButtonGroup>
    )
}

export default PageIndicator