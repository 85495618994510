import { DeleteOutlineOutlined, Pause, PauseOutlined, PlayArrow, PlayArrowOutlined, Send, SendOutlined } from "@mui/icons-material"
import { useAudioRecorder } from "react-audio-voice-recorder"
import {useState, useEffect} from 'react'

const AudioRecorder = ({ returnAudio }) => {
    const audioRecorder = useAudioRecorder()

    const [isDeleted, setIsDeleted] = useState(false)
    const [soundLevels, setSoundLevels] = useState([
        10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10
    ]) // , 10, 10, 10, 10, 10

    useEffect(() => {
        if (audioRecorder.recordingBlob){
            returnAudio(!isDeleted ? audioRecorder.recordingBlob : null)
        }
        
    }, [audioRecorder.recordingBlob])

    useEffect(() => {
        audioRecorder.startRecording()
        console.log('started recording')
        return () => {
            audioRecorder.stopRecording()
            console.log('stopped recording')
        }
    }, [])

    useEffect(() => {
        if (audioRecorder.recordingTime >= soundLevels.length - 5) {
            const newSoundLevels = [...soundLevels.slice(1), 10]
            newSoundLevels[soundLevels.length - 5] = Math.floor(Math.random() * 100)
            setSoundLevels(newSoundLevels)
        } else {
            const newSoundLevels = [...soundLevels]
            newSoundLevels[audioRecorder.recordingTime] = Math.floor(Math.random() * 100)
            setSoundLevels(newSoundLevels)
        }
    }, [audioRecorder.recordingTime])


    return (
        <div className='flex items-center justify-center gap-2 p-2 border border-[#C3C2C6] rounded-lg min-h-[50px]'>
            <DeleteOutlineOutlined fontSize='small' className='cursor-pointer text-error' onClick={() => {
                setIsDeleted(true)
                audioRecorder.stopRecording()
                // caught in useEffect after recording stops
            }} />
            <div className="grow self-stretch grid gap-1 items-center" style={{
                gridTemplateColumns: `repeat(${soundLevels.length}, 1fr)`,
            }}>
                {soundLevels.map((soundLevel, index) => (
                    <div 
                    key={index} 
                    className={'rounded-full' + (soundLevel !== 10 ? ' bg-yellowCustom' : ' bg-gray-300')}
                    style={{
                        height: `${soundLevel}%`,
                        transition: 'all 0.5s ease-in-out'
                    }}
                    />
                ))}
            </div>
            {new Date(audioRecorder.recordingTime * 1000).toISOString().substr(14, 5)}
            { audioRecorder.isPaused ? (
                <PlayArrow fontSize='small' className='cursor-pointer' onClick={audioRecorder.togglePauseResume} />
            ) : (
                <Pause fontSize='small' className='cursor-pointer' onClick={audioRecorder.togglePauseResume} />
            )}
            <Send fontSize='small' className='cursor-pointer' onClick={async () => {
                audioRecorder.stopRecording()
                // caught in useEffect after recording stops
            }} />
        </div>
    )
}

export default AudioRecorder