import { cloneElement, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { Alert, Button, InputBase, Paper, Slider, SliderMarkLabel, SliderThumb, SliderValueLabel, Snackbar } from "@mui/material";
import { AutoAwesomeOutlined, Menu, MenuRounded, MonetizationOnOutlined, SearchOutlined, ShoppingBagOutlined, SupportAgentOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import { createRequest, readRequest } from "../../utils/crud";
import { useNavigate } from "react-router-dom";

const slidermarks = [
    {
        value: 1,
        label: '20 Journeys',
    }, {
        value: 2,
        label: '40 Journeys',
    }, {
        value: 3,
        label: '80 Journeys',
    }, {
        value: 4,
        label: '160 Journeys',
    }, {
        value: 5,
        label: '320 Journeys',
    }, {
        value: 6,
        label: '640 Journeys',
    }, {
        value: 7,
        label: '1000+ Journeys',
    }
];
const valueText = (value) => {
    return `${value} Journeys`;
}
const SliderThumbComponent = (props) => {
    const { children, ...other } = props;
    console.log(props);
    return (
        <SliderThumb {...other}>
            {children}
            <MenuRounded htmlColor="#fff" fontSize="smaller" />
            <div className={"absolute -top-9 bg-[#F6FAFE] rounded-full p-1 px-3 flex items-center gap-1 border border-[#FBBC05] text-black text-sm shadow-md" + (props.ownerState.value !== 2 ? " hidden" : "")}>
                <AutoAwesomeOutlined htmlColor="#FBBC05" fontSize="small" />
                Recommended
            </div>
        </SliderThumb>
    );
}
const SliderMarkLabelComponent = (props) => {
    const { children, ...other } = props;
    return (
        <SliderMarkLabel {...other}>
            <div className="flex flex-col items-center">
                <div className="font-bold" style={{ fontSize: "1.15rem" }}>
                    {children.split(" ")[0]}
                </div>
                <div className="-mt-1" style={{ fontSize: ".8rem", color: "#87848D" }}>
                    {children.split(" ")[1]}
                </div>
            </div>
        </SliderMarkLabel>
    );
}

const Credits = () => {
    const [searchString, setSearchString] = useState("");
    const [remainingTracks, setRemainingTracks] = useState(0);
    const [sliderValue, setSliderValue] = useState(2);
    const [snackbarDetails, setSnackbarDetails] = useState({ open: false, message: "", severity: "success" });

    const fetchInitialData = async () => {
        const res = await readRequest("api/v1/tracks/remaining_tracks/fetch");
        if (res?.status === 200 || res?.status === 201) {
            setRemainingTracks(res?.data === null ? 0 : res?.data);
        }
    };

    const handleBuyCredits = async () => {
        if (sliderValue === 7) {
            return;
        }
        const res = await createRequest("api/v1/payments/checkout", { quantity: 2 ** (sliderValue) * 10 });
        console.log(res);
        if (res?.status === 200 || res?.status === 201) {
            window.location.href = res?.data?.checkout_session_url;
        }
    }

    useEffect(() => {
        if (window.location?.state?.success !== undefined) {
            setSnackbarDetails({
                open: true,
                message: window.location?.state?.success
                    ? "Credit purchase successful"
                    : "Credit purchase failed",
                severity: window.location?.state?.success ? "success" : "error",
            });
        }

        fetchInitialData();
    }, []);

    return (
        <div className="flex bg-[#F7F9FB]">
            <Sidebar />
            <Snackbar
                open={snackbarDetails.open}
                autoHideDuration={6000}
                onClose={() => setSnackbarDetails({ ...snackbarDetails, open: false })}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert severity={snackbarDetails.severity} sx={{ width: "100%" }}>
                    {snackbarDetails.message}
                </Alert>
            </Snackbar>

            <div className="flex flex-col w-full h-screen p-4 gap-4 items-center">
                <div className="flex justify-between items-center gap-4 self-stretch">
                    <Paper
                        className="grow px-2 py-0 flex items-center"
                        sx={{ boxShadow: "none" }}>
                        <SearchOutlined />
                        <InputBase
                            id="outlined-search"
                            placeholder="Search for journey..."
                            type="search"
                            size="small"
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            className="grow"
                            inputProps={{
                                sx: {
                                    padding: ".5rem ",
                                },
                            }}
                        />
                    </Paper>
                    <div className="bg-white rounded-lg p-2">
                        <span className="text-[#1877f2] font-bold">{remainingTracks}</span> journeys remaining
                    </div>
                </div>
                <div className="font-bold text-2xl mt-4">
                    Buy Credits to stay in the research
                </div>
                <div className='bg-white rounded-2xl border border-[#C3C1C6] p-4 mt-4 w-8/12'>
                    <div className="text-start">
                        <MonetizationOnOutlined />
                        <span className='ml-2 font-xl font-bold'>Buy Credits</span>
                    </div>
                    <div className="text-start mt-12 mb-12 mx-8">
                        <Slider
                            value={sliderValue}
                            onChange={(e, value) => setSliderValue(value)}
                            getAriaValueText={valueText}
                            aria-labelledby="discrete-slider-custom"
                            step={1}
                            marks={slidermarks}
                            min={1}
                            max={7}
                            color="secondary"
                            track={false}
                            size="large"
                            slots={{ thumb: SliderThumbComponent, markLabel: SliderMarkLabelComponent }}
                        />
                    </div>
                    <div className="flex justify-between items-center mt-4 gap-8">
                        <Button variant="outlined" className='grow' color='secondary' disableRipple sx={{ justifyContent: 'space-between', px: '2rem', backgroundColor: '#F6FAFE', cursor: 'default' }} >
                            {sliderValue === 7 ? 'Need more credits? Contact our sales team for details' : <>
                                <div className="text-black">
                                    <span>Credits: </span>
                                    <span className="font-bold">
                                        {`${2 ** (sliderValue) * 10} journeys`}
                                    </span>
                                </div>
                                <div>
                                    <span className="text-black">Price: </span>
                                    <span className="font-bold">
                                        {`$${2 ** (sliderValue) * 5 / 2}.00`}
                                    </span>
                                </div>
                            </>}
                        </Button>
                        <Button variant="contained" className="w-3/12" color='secondary' onClick={handleBuyCredits}>
                            {sliderValue === 7 ? <div>
                                <SupportAgentOutlined fontSize="small" />
                                <span className="ml-2">Contact Sales</span>
                            </div> : <div>
                                <ShoppingBagOutlined fontSize="small" />
                                <span className="ml-2">Buy Now</span>
                            </div>}
                        </Button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Credits;